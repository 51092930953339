import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import style from "./Consent.module.scss";

const Consent = () => {
  const [cookieDialogVisible, setCookieDialogVisible] = useState(false);

  useEffect(() => {
    if (!window.isCookieConsentAccepted()) {
      setCookieDialogVisible(true);
    }
  }, []);

  if (!cookieDialogVisible) {
    return null;
  }

  return (
    <div className={style.container}>
      <h2>Prywatność</h2>
      <p>
        Akceptuję{" "}
        <a href="/rs-policy-privacy.pdf" target="_blank">
          Politykę Prywatności
        </a>
        . Zgadzam się na wykorzystanie cookies w celu analizy ruchu strony.
      </p>
      <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        <button
          className={style.consent}
          onClick={() => {
            setCookieDialogVisible(false);
            window.acceptCookieConsent();
          }}
        >
          Zgoda
        </button>
      </div>
      <button
        className={style.close}
        onClick={() => setCookieDialogVisible(false)}
      >
        <FaTimes />
      </button>
    </div>
  );
};

export default Consent;
