import slideStyle from "./InfoPage.module.scss";
import Badge from "../Badge";
import ContactAppendix from "./ContactAppendix";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Angażuj, zaskakuj, zachęcaj do powrotów. Zwiększaj sprzedaż"
        title="z systemem promocji i rabatów"
        hints={[
          "Drukowanie voucherów bezpośrednio pod paragonem",
          "Vouchery na ekranie Klienta",
          "Lojalizacja",
        ]}
      />
      <div className={slideStyle.section}>
        <div>
          <h2>
            System promocji i rabatów to nie tylko narzędzie do zwiększenia
            sprzedaży, ale także sposób na zbudowanie lojalności Klientów.
          </h2>
          <p>
            Dzięki naszemu systemowi promocji i rabatów możesz zaskakiwać
            Klientów atrakcyjnymi ofertami, które zwiększają ich zadowolenie i
            zachęcają do powrotów. Drukuj vouchery bezpośrednio pod paragonem,
            wyświetlaj promocje na ekranie Klienta, a także korzystaj z funkcji
            lojalizacji, by nagradzać Klientów za ich aktywność.
          </p>
          <p style={{ fontWeight: "bold" }}>Już niebawem w Rapidshop!</p>
        </div>

        <div
          className={slideStyle.heading}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <h1>
            Zrób więcej i obsłuż lepiej
            <br />
            dzięki magii sztucznej inteligencji AI w Twoim POS!
          </h1>
        </div>

        <ContactAppendix />
      </div>
    </>
  );
};

export default KioskAI;
