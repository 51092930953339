import React, { useEffect } from "react";
import NavbarButton from "./NavbarButton";
import {
  FaChevronDown,
  FaChevronRight,
  FaDollarSign,
  FaSquare,
} from "react-icons/fa";
import { useFloating } from "@floating-ui/react";
import styles from "./Navbar.module.scss";
import classNames from "classnames";

const MenuButton = ({ icon: Icon, href, title, subtitle, open, ...props }) => {
  return (
    <a
      href={href}
      className={classNames(styles.menuButton, open && styles.menuButtonOpen)}
      {...props}
    >
      <div className={styles.menuIcon}>
        <Icon />
      </div>
      <div className={styles.menuText}>
        <div className={styles.menuTextTitle}>{title}</div>
        <div className={styles.menuTextSubtitle}>{subtitle}</div>
      </div>
    </a>
  );
};

const Possibilities = ({isMainPage}) => {
  const [open, setOpen] = React.useState(false);

  const { refs, floatingStyles } = useFloating({
    placement: "bottom-start",
    open,
    onOpenChange: setOpen,
    strategy: "fixed",
  });

  useEffect(() => {
    if (open) {
      window.document.documentElement.style.overflow = 'hidden';
      refs.floating.current.style.pointerEvents = 'auto';
    } else {
      window.document.documentElement.style.overflow = 'auto';
      refs.floating.current.style.pointerEvents = 'none';
    }
  }, [open, refs.floating]);

  return (
    <>
      <NavbarButton
        ref={refs.setReference}
        isMainPage={isMainPage}
        onClick={() => setOpen(!open)}
        icon={FaChevronDown}
      >
        Możliwości
      </NavbarButton>
      {open && 
        <div className={styles.menuOverlay}></div>
      }
      <div
        ref={refs.setFloating}
        className={styles.menu}
        style={{ ...floatingStyles }}
      >
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/chmurowy-system-zarzadzania-sprzedaza"
          title="Rapidshop Cloud"
          subtitle="Twoje transakcje, przychody, zamówienia widoczne w czasie rzeczywistym w naszej Chmurze. Dostępna z każdego urządzenia"
          style={{ transitionDelay: "0s" }}
        />
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/kioski-samoobslugowe-z-chatem-gpt"
          title="Kioski samoobsługowe z AI"
          subtitle="Zwiększaj sprzedaż nawet o 30%, redukuj koszty, przyciągaj Klientów"
          style={{ transitionDelay: "0.1s" }}
        />
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/ai-w-twoim-punkcie-sprzedazy"
          title="Rapidshop AI"
          subtitle="Zarządzanie Twoim sklepem, restauracją, kawiarnią z wykorzystaniem sztucznej inteligencji. Wirtualny asystent Klienta w Kiosku"
          style={{ transitionDelay: "0.1s" }}
        />
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/aplikacja-sprzedazowa-android"
          title="Aplikacja POS"
          subtitle="Ergonomiczna aplikacja dla Twoich kelnerów, sprzedawców. Interaktywny ekran Klienta: e-Paragon, vouchery zawsze na Twojej ladzie."
          style={{ transitionDelay: "0.2s" }}
        />
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/system-ekranow-kuchennych"
          title="Rapid KDS"
          subtitle="Prosty w obsłudze ekran kuchenny, złączony z Twoimi drukarkami, który usprawni wydawanie zamówień oraz komunikację w kuchni"
          style={{ transitionDelay: "0s" }}
        />
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/system-promocji-i-rabatow"
          title="Promocje i rabaty"
          subtitle="Generuj kupony, przypisuj wirtualne pieczątki, angażuj w aplikacji"
          style={{ transitionDelay: "0.1s" }}
        />
        <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/latwe-zarzadzanie-sprzetem"
          title="Łatwe zarządzenie sprzętem"
          subtitle="Dzięki Rapid SAFEPOS, zmniejszysz ilość awarii, zwiększysz bezpieczeństwo i kontrolę nad stanem urządzeń"
          style={{ transitionDelay: "0.1s" }}
        />

        {/* <MenuButton
          open={open}
          icon={FaChevronRight}
          href="/smart-urzadzenia-na-stolikach"
          title="Smart urządzenia na stolikach"
          subtitle="Nowość: obsługuj Klientów nawet gdy Twoi kelnerzy są zajęci. Specjalne oferty kontekstowe dla zwiększenia sprzedaży"
          style={{ transitionDelay: "0.2s" }}
        /> */}
      </div>
    </>
  );
};

export default Possibilities;
