import slideStyle from "./InfoPage.module.scss";
import Badge from "../Badge";
import ContactAppendix from "./ContactAppendix";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Szybkie, lekkkie i zwiększające sprzedaż"
        title="urządzenia POS"
        hints={[
          "System Android",
          "Proste i energooszczędne",
          "Zintegrowane z czytnikami i drukarkami",
        ]}
      />
      <div className={slideStyle.section}>
        <div>
          <h2>Nowe sposoby na sprzedaż</h2>
          <p>
            Rynek urządzeń POS w ostatnich latach przeszedł ogromną rewolucję.
            Dziś sprzęt POS to nie tylko komputer zliczający kolejne linie
            zamówienia. Dzisiaj są to multimedialne kombajny, które nie tylko
            przyjmują zamówienie, ale są też narzędziem do budowania relacji z
            Klientem.
          </p>
          <p>
            W Rapidshop wykorzystujemy moc i potencjał nowoczesnych urządzeń
            POS, aby napędzać Twoją sprzedaż.
          </p>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
              marginTop: "50px",
              fontWeight: "bold",
            }}
          >
            <div>CZYTNIKI NFC</div>
            <div>KARTY KLIENTA</div>
            <div>CZYTNIKI QR</div>
            <div>E-PARAGONY</div>
          </div>
        </div>
      </div>

      <div className={slideStyle.posContainer}>
        <div className={slideStyle.biggerEmbeddedImg}>
          <img src={require("../assets/devices.png")} alt="POS" />
        </div>
      </div>

      <div className={slideStyle.section}>
        <div
          className={slideStyle.heading}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <h1>
            Zrób więcej i obsłuż lepiej
            <br />
            dzięki magii sztucznej inteligencji AI w Twoim POS!
          </h1>
        </div>

        <ContactAppendix />
      </div>
    </>
  );
};

export default KioskAI;
