import posicons from "../../assets/posicons.png";

const OtherElements = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "80px" }}>
      <div>
        <h2>
          Lokalizatory, pagery, ekrany na kuchnię (KDS), aplikacje mobilne...
          <span style={{ display: "block", fontSize: "1.5em" }}>
            Rapidshop oferuje więcej niż się spodziewasz
          </span>
          i to w jednym miejscu.
        </h2>
        <img src={posicons} alt="posicons" style={{ width: "100%" }} />
        <div style={{ marginTop: "30px" }}>
          <div>
            <p>
              Chcemy, abyś miał w naszym systemie wszystko, czego potrzebujesz
              do prowadzenia Twojej restauracji. Dlatego w naszej ofercie
              znajdziesz nie tylko urządzenia POS z programem AI, ale także
              lokalizatory, pagery, ekrany na kuchnię (KDS), aplikacje mobilne,
              sklepy internetowe i wiele innych rozwiązań.
            </p>

            <p>Już niedługo jeszcze więcej nowości.</p>

            <p>
              Sprawdź ofertę naszych subskrypcji, aby dowiedzieć się o naszych
              innowacyjnych rozwiązaniach dla detailu i gastronomii.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherElements;
