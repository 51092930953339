import { SimpleInterpolation } from 'react-scroll-motion';

export const NavbarBottomBarAnimation = (viewport) => (value) => {
  return {
    width: viewport.width,
    transform: `translate3d(${SimpleInterpolation(-viewport.width, 0, value)}px, 0, 0)`,
  }
}

export const navbarBottomKeyframes = [
  {
    time: 0,
    animation: [
      ['navbarBottomBar', [0]]
    ],
  },
  {
    time: 0.05,
    animation: [
      ['navbarBottomBar', [0]]
    ],
  },
  {
    time: 0.07,
    animation: [
      ['navbarBottomBar', [1]]
    ],
  },
  {
    time: 1,
    animation: [
      ['navbarBottomBar', [1]]
    ],
  },
];

export const navbarButtonKeyframes = [
  {
    time: 0,
    animation: [
      ['fade', [0]],
    ]
  },
  {
    time: 0.06,
    animation: [
      ['fade', [0]],
    ]
  },
  {
    time: 0.07,
    animation: [
      ['fade', [1]],
    ]
  }
];

export const navbarIconStrokeKeyframes = [
  {
    time: 0.02,
    animation: [
      ['svgStrokeProgress', [0]],
    ]
  },
  {
    time: 0.04,
    animation: [
      ['svgStrokeProgress', [1]],
    ]
  }
];

export const navbarIconKeyframes = [
  {
    time: 0.04,
    animation: [
      ['fade', [0]],
    ]
  },
  {
    time: 0.06,
    animation: [
      ['fade', [1]],
    ]
  }
];

export const logoBarKeyframes = [
  {
    time: 0.09,
    animation: [
      ['clip', [0, 0, 0, 100, 0]],
    ]
  },
  {
    time: 0.11,
    animation: [
      ['clip', [0, 0, 100, 100, 1]],
    ]
  }
]
