import React from "react";
import style from "./AIStars.module.scss";

const AIStars = () => {
  return (
    <div class={style.aiStarsAnimation}>
      {[1, 2, 3, 4].map((i) => (
        <svg key={i} viewBox="0 0 100 100">
          <path
            d="M 50 10 C 53.3333 20 56.6667 30 60 40 L 80 49 L 60 60 L 50 90 L 40 60 L 19 50 L 40 40 Z"
            stroke="white"
            fill="transparent"
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
      ))}
    </div>
  );
};

export default AIStars;