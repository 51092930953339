import { Animator } from "react-scroll-motion";
import styles from "./Profit.module.scss";

const Profit = ({children, video, title, descr, ...props}) => {
  return <Animator className={styles.container} {...props}>
    <video className={styles.video} autoPlay muted loop>
      <source src={video} />
    </video>
    <div className={styles.overlay}></div>
    <div className={styles.content}>
      <h3>{title}</h3>
      <p>{descr}</p>
    </div>
  </Animator>;
};

export default Profit;
