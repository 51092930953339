import React from "react";
import style from "./InfoPage.module.scss";

const ContactAppendix = () => {
  return (
    <div className={style.contactAppendix}>
      <h2>Chcesz wiedzieć więcej?</h2>
      <div>
        Czy wiesz, że bazowy abonament usługi Rapidshop{" "}
        <b>to tylko 3,19 zł netto</b> za dzień? Już w tej cenie dostajesz sporo!
      </div>
      <p>
        Zachęcamy do <a href="/kontakt">pozostawienia kontaktu</a>, aby
        dowiedzieć się więcej i zamówić instalację rozwiązania Rapidshop w Twoim
        punkcie. Nasz doradca zaproponuje dogodny termin spotkania online, na
        którym przedstawi Ci, jak nowe technologie mogą realnie pomóc w
        zwiększeniu zysku Twojej restauracji.
      </p>
      <a href="/kontakt" className={style.contactAppendixCta}>
        Pozostaw kontakt
      </a>
    </div>
  );
};

export default ContactAppendix;
