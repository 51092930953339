import React from 'react';
import style from "../App.module.scss";
import HeroTwo from "./Hero";
import FirstSlide from "./FirstSlide";
import SolutionAmos from './SolutionAmos';
import Solution from "./Solution";
import Offer from './Offer';
import ElementKiosk from './Solution/ElementKiosk';
import ElementHandheld from './Solution/ElementHandheld';
import ElementTabletop from './Solution/ElementTabletop';
import OtherElements from './Solution/OtherElements';

const LandingPage = () => {
  return (
    <>
      <HeroTwo page={0} />
      <Offer page={1} />
      <Solution page={2}>
        <ElementKiosk />
      </Solution>
      <Solution page={3}>
        <ElementHandheld />
      </Solution>
      <Solution page={4}>
        <ElementTabletop />
      </Solution>
      <Solution page={5}>
        <OtherElements />
      </Solution>
      <FirstSlide page={6} />
      <SolutionAmos page={7} />
    </>
  );
};

export default LandingPage;
