import React, { useContext } from "react";
import { Animator, ScrollPage, ViewportContext } from "react-scroll-motion";
import { restMargins } from "../../animatorUtils";
import video from "./4254313-hd_2048_1080_25fps.mp4";
import { TypeAnimation } from "react-type-animation";
import style from "./Hero.module.scss";

const Gradient = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={700}
    height={700}
    className={style.rotatingGradient}
  >
    <defs>
      <linearGradient
        id="a"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
        gradientTransform="rotate(150 .5 .5)"
      >
        <stop offset="0%" stopColor="hsl(122, 100%, 72%)" />
        <stop offset="100%" stopColor="hsl(212, 84%, 50%)" />
      </linearGradient>
      <filter
        id="b"
        width="140%"
        height="140%"
        x="-20%"
        y="-20%"
        colorInterpolationFilters="sRGB"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
      >
        <feTurbulence
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          baseFrequency="0.005 0.003"
          numOctaves={2}
          result="turbulence"
          seed={17}
          stitchTiles="stitch"
          type="fractalNoise"
        />
        <feGaussianBlur
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          in="turbulence"
          result="blur"
          stdDeviation="20 0"
        />
        <feBlend
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          in="SourceGraphic"
          in2="blur"
          mode="color-burn"
          result="blend"
        />
        <feColorMatrix
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          in="blend"
          result="colormatrix"
          type="saturate"
          values={3}
        />
      </filter>
    </defs>
    <path fill="url(#a)" d="M0 0h700v700H0z" filter="url(#b)" />
  </svg>
);

const Hero = ({ page }) => {
  const viewport = useContext(ViewportContext);
  const containersWidth = viewport.width < 1300 ? viewport.width : 1300;

  const headerKeyframes = [
    {
      time: 0,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
  ];

  const thunderKeyframes = [
    {
      time: 0,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0.5,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
  ];

  const stickyVideo = [
    {
      time: 0,
      animation: [
        ["sticky", [0, 0, viewport.width, viewport.height, 1]],
        ["fade", [1]],
        ["zoom", [1]],
      ],
    },
    {
      time: 0.3,
      animation: [
        ["stickyPixels", [0, 0, viewport.width, viewport.height, 1]],
        ["opacity", [1]],
        ["fade", [1]],
      ],
    },
    {
      time: 0.4,
      animation: [
        ["stickyPixels", [0, 0, viewport.width, viewport.height, 1]],
        ["opacity", [1]],
        ["fade", [1]],
      ],
    },
  ];

  return (
    <ScrollPage page={page} length={1} className={style.container}>
      <Animator
        tag="video"
        src={video}
        keyframes={stickyVideo}
        style={{ objectFit: "cover", filter: "saturate(30%) brightness(0.3)" }}
        autoPlay
        loop
        muted
      />
      {/* <Animator tag="div" keyframes={imageKeyframes}>
        <Logo />
      </Animator> */}

      <Animator
        keyframes={thunderKeyframes}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
      >
        <div
          className={style.rotatingGradientContainer}
          style={{
            background: "#FFF",
            width: "250px",
            height: "450px",
            clipPath:
              'path("M23.3595 450 243 159.3h-103.6665L243 0h-162.441L4.5 237.843h86.886z")',
          }}
        >
          <Gradient />
        </div>
      </Animator>

      <Animator keyframes={headerKeyframes} className={style.autotype}>
        <h1>
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              "Zwiększ swoją sprzedaż",
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              "Ułatwiaj sobie pracę",
              1000,
              "Więcej czasu dla Ciebie",
              1000,
              "Twój nowy POS!",
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={{ display: "inline-block" }}
            repeat={Infinity}
          />
        </h1>
        <h2 style={{ marginTop: "20px" }}>
          Rapidshop to nowoczeny system z AI dla detalu i restauracji. Zwiększa sprzedaż i ułatwia pracę.
        </h2>
      </Animator>
    </ScrollPage>
  );
};

export default Hero;

/*
<div className={style.hero}>
          <div className={style.heroContent}>
            <div className={style.heroContentTexts}>
              <h2>The world is built on ideas.</h2>
              <h1>We believe in Your</h1>
              <img className={style.bigLogo} src={logo} alt="appidea"/>
            </div>
          </div>
        </div>
 */
