import React from "react";
import styles from "./Badge.module.scss";

const Badge = ({ children, fgColor, bgColor }) => {
  return (
    <div
      className={styles.container}
      style={{ "--bg-color": "#235f7a", "--fg-color": "#FFF" }}
    >
      <i className={styles.arrow}></i>
      <span className={styles.content}>{children}</span>
    </div>
  );
};

export default Badge;
