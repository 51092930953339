import slideStyle from "./InfoPage.module.scss";
import Badge from "../Badge";
import ContactAppendix from "./ContactAppendix";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Nowoczesna i zintegrowana"
        title="Aplikacja KDS"
        hints={[
          "Pełna kontrola nad stanem zamówień",
          "Drukuj poszczególne pozycje",
        ]}
      />

      <div className={slideStyle.section}>
        <div>
          <h2>
            Aplikacja KDS to centrum dowodzenia nad Twoją kuchnią. Dzięki niej
            Twoi kelnerzy doskonale wiedzą, co i kiedy przygotować.
          </h2>
          <p>
            Nasza aplikacja KDS to nie tylko wygoda, ale także sposób na
            zwiększenie sprzedaży i zadowolenia Klientów. Dzięki ergonomicznemu
            designowi i intuicyjnemu interfejsowi, Twoi kucharze na bieżąco
            aktualizują stan zamówień, co przekłada się na szybkość obsługi
            Klientów i wzrost obrotów.
          </p>
          <p>
            W naszej dotykowej aplikacji KDS Kelnerzy mogą na bieżąco drukować
            zamówienia na Twoją drukarkę KDS.{" "}
            <b>
              Zgubiony wydruk nie jest już więcej problemem, a Twoi Klienci
              zawsze dostają to, na co czekają.
            </b>
          </p>
          <p>
            Dobierz <b>Ekran Numerków</b> do swojego KDS. Twoi pracownicy z
            poziomu KDS mogą zmieniać stany zamówień na ekranie dla Twoich
            klientów, i ogłaszać możliwość odbioru dań, co jeszcze bardziej
            usprawnia cały proces na Kuchni i wydawce.
          </p>
          <p>
            Wkrótce w aplikacji KDS pojawią się także nowe funkcje{" "}
            <b>Rapidshop AI</b>, które jeszcze bardziej usprawnią procesy w
            Twojej kuchni.
          </p>
        </div>

        <div
          className={slideStyle.heading}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <h1>
            Zainwestuj w nowe technologie
            <br />i zwiększ sprzedaż, optymalizując pracę!
          </h1>
        </div>

        <ContactAppendix />
      </div>
    </>
  );
};

export default KioskAI;
