import React, { useContext } from "react";
import { Animator, ScrollPage, ViewportContext } from "react-scroll-motion";
import style from "./FirstSlide.module.scss";
import { restMargins } from "../../animatorUtils";
import background from "./image.png";
import laskaAi from "./img-HXDo4Hkq58IkMzCJEaygI3Yn.png";
import AIStars from "../../AIStars";

const FirstSlide = ({ page }) => {
  const viewport = useContext(ViewportContext);

  const header1Keyframes = [
    {
      time: -0.001,
      animation: [
        [
          "stickyPixels",
          [0, 0, viewport.width, viewport.height, 0],
          ["move", [0, 0]],
        ],
      ],
    },
    {
      time: 0,
      animation: [
        [
          "stickyPixels",
          [0, 0, viewport.width, viewport.height, 1],
          ["move", [0, 0]],
        ],
      ],
    },
    {
      time: 0.01,
      animation: [
        ["stickyPixels", [0, 0, viewport.width, viewport.height, 1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [0, 0, viewport.width, viewport.height, 1]],
        ["move", [-100, 0]],
      ],
    },
  ];

  const header2Keyframes = [
    {
      time: 0,
      animation: [
        ["stickyPixels", [...restMargins(1024, 768, viewport), 0]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0.01,
      animation: [
        ["stickyPixels", [...restMargins(1024, 768, viewport), 1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [...restMargins(1024, 768, viewport), 1]],
        ["move", [-100, 0]],
      ],
    },
  ];

  const movingLaska = [
    {
      time: 0,
      animation: [["move", [0, 0]]],
    },
    {
      time: 1,
      animation: [["move", [-300, 0]]],
    },
  ];

  const movingDesc = [
    {
      time: 0,
      animation: [["stickyPixels", [...restMargins(1024, 768, viewport), 0]]],
    },
    {
      time: 0.01,
      animation: [["stickyPixels", [...restMargins(1024, 768, viewport), 1]]],
    },
    {
      time: 1,
      animation: [["stickyPixels", [...restMargins(1024, 768, viewport), 1]]],
    },
  ];

  return (
    <ScrollPage
      page={page}
      length={2}
      className={style.container}
      style={{ background: " #06192d" }}
    >
      <Animator keyframes={header1Keyframes}>
        <img
          src={background}
          style={{
            width: "calc(100% + 100px)",
            height: "100%",
            objectFit: "cover",
          }}
          alt="Ilustracja"
        />

        <Animator
          tag="div"
          keyframes={header2Keyframes}
          style={{ textAlign: "left" }}
        >
          <div style={{ width: viewport.width + "px", position: "absolute" }}>
            <Animator keyframes={movingLaska}>
              <img src={laskaAi} alt="Ilustracja" />
            </Animator>
          </div>
        </Animator>
      </Animator>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.7)",
        }}
      ></div>
      <Animator tag="div" keyframes={movingDesc}>
        <div className={style.descContainer}>
          <h1 style={{ color: "#FFF", marginBottom: 0 }}>
            Powitaj Rapidshop AI
          </h1>

          <AIStars />

          <div>AI w końcu w Twoim punkcie sprzedaży!</div>
          <div
            style={{
              fontSize: "1.4em",
              fontWeight: "bold",
              marginBottom: "1.6em",
            }}
          >
            To nie jest zwykła zmiana. To rewolucja w obsłudze Twoich Klientów i
            Twojego biznesu.
          </div>
          <div>
            Nasze kioski rozmawiają z Klientem jak prawdziwy człowiek. Dzięki
            mocy najnowszych modeli językowych AI, nasze kioski są w stanie
            zrozumieć i odpowiedzieć na pytania Klientów, a także zaproponować
            im produkty, które ich zainteresują.
          </div>
        </div>
      </Animator>
    </ScrollPage>
  );
};

export default FirstSlide;
