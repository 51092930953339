import slideStyle from "./InfoPage.module.scss";
import Badge from "../Badge";
import ContactAppendix from "./ContactAppendix";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Rapidshop Cloud to intuicyjny"
        title="chmurowy system zarzadzania Twoją sprzedażą"
        hints={[
          "Widoczność operacji",
          "Dostępna wszędzie tam, gdzie Internet",
          "Kontrola stanu urządzeń",
        ]}
      />
      <div className={slideStyle.section}>
        <div>
          <p>
            Zarządzanie biznesem jeszcze nigdy nie było tak proste i efektywne
            jak z Rapidshop Cloud. Dzięki naszemu systemowi POS, opartemu na
            chmurze, otrzymujesz natychmiastowy dostęp do wszelkich informacji
            dotyczących Twojego przedsiębiorstwa. <b>Zyskaj pełną kontrolę</b>{" "}
            nad każdym aspektem swojej działalności, od zarządzania produktami,
            przez monitorowanie transakcji, aż po zarządzanie zespołem i
            urządzeniami.
          </p>

          <h2>Łatwe i potężne zarządzanie produktami</h2>

          <p>
            Rapidshop Cloud pozwala na intuicyjne zarządzanie bazą produktów,
            dodawanie atrybutów istotnych dla narzędzi AI, oraz tworzenie
            zarówno prostych, jak i złożonych produktów. <b>Produkty złożone</b>{" "}
            to kompozycje tworzone z produktów prostych, które mogą mieć stałą
            cenę lub cenę zależną od wybranej kompozycji. Produkty możesz
            organizować w prezentacje, co pozwala na tworzenie menu
            dostosowanego do różnych kanałów, takich jak POS, kioski, strona
            internetowa czy portale dostaw.
          </p>

          <h2>Zwiększona widoczność transakcji</h2>

          <p>
            Dzięki Rapidshop Cloud, przedsiębiorca może śledzić listę transakcji
            oraz generować raporty dotyczące sprzedaży w czasie rzeczywistym.{" "}
            <b>Wszystkie transakcje są dostępne w jednym miejscu</b>,
            niezależnie od kanału, z którego pochodzą. To ogromne ułatwienie
            pozwala na bieżąco analizować wyniki finansowe i podejmować świadome
            decyzje biznesowe.
          </p>

          <h2>Zarządzanie pracownikami</h2>

          <p>
            Nasze oprogramowanie umożliwia nadawanie uprawnień Twojemu
            zespołowi, przeglądanie podsumowań czasu pracy i przypisywanie
            transakcji do konkretnych pracowników.{" "}
            <b>Możesz generować raporty dotyczące wydajności</b>
            pracowników, co pozwala na efektywne zarządzanie i motywowanie
            Twojego zespołu do jak najlepszej pracy.
          </p>

          <h2>Zarządzanie urządzeniami</h2>

          <p>
            Rapidshop Cloud daje Ci również możliwość kontrolowania stanu Twoich
            urządzeń. Możesz łatwo parować nowe urządzenia i szybko zgłaszać
            ewentualne problemy.{" "}
            <b>
              Wszystko po to, abyś mógł się skupić na tym, co naprawdę ma
              znaczenie
            </b>
            – rozwijaniu swojego biznesu.
          </p>

          <h2>Już niedługo</h2>
          <p>
            Nowe narzędzia AI w naszym systemie pozwolą Ci na jeszcze bardziej
            efektywne zarządzanie biznesem. Dzięki sztucznej inteligencji, Twój
            system POS będzie w stanie analizować dane i proponować Ci
            optymalizacje, które pomogą Ci w{" "}
            <b>przyciąganiu Klientów i zwiększaniu obrotów</b>. Wiele
            codziennych zadań będzie wykonywanych automatycznie, co pozwoli Ci
            skupić się na najważniejszych aspektach prowadzenia biznesu.
          </p>
          <ContactAppendix />
        </div>
      </div>
    </>
  );
};

export default KioskAI;
