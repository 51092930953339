export const restMargins = (w, h, viewport) => {
    let left = (viewport.width - w) / 2;
    let top = (viewport.height - h) / 2;

    if (viewport.width < w) {
        w = viewport.width;
        left = 0;
    }
    if (viewport.height < h) {
        h = viewport.height;
        top = 0;
    }

    return [left, top, w, h];
}