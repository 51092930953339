import React, { useCallback, useContext, useEffect } from "react";
import { restMargins } from "../../animatorUtils";
import { Animator, ScrollPage, ViewportContext } from "react-scroll-motion";
import slideStyle from "./FirstSlide.module.scss";
import Profit from "./Profit";

const Offer = ({ page }) => {
  const [lang, setLang] = React.useState("pl");
  const viewport = useContext(ViewportContext);

  const [mgLeft] = restMargins(387, 101, viewport);
  const containersWidth = viewport.width < 1300 ? viewport.width : 1300;

  const overlayKeyframes = [
    {
      time: -0.001,
      animation: [
        ["sticky", [0, 0, 100, 100, 0]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0,
      animation: [
        ["sticky", [0, 0, 100, 100, 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["sticky", [0, 0, 100, 100, 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
  ];

  const headerKeyframes = [
    {
      time: -0.001,
      animation: [
        [
          "stickyPixels",
          [...restMargins(containersWidth, viewport.height, viewport), 0],
        ],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0,
      animation: [
        [
          "stickyPixels",
          [...restMargins(containersWidth, viewport.height, viewport), 1],
        ],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        [
          "stickyPixels",
          [...restMargins(containersWidth, viewport.height, viewport), 1],
        ],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
  ];

  const wjazd = [
    {
      time: 0,
      animation: [
        ["fade", [0]],
        ["move", [-300, 0]],
        ["zoom", [1]],
      ],
    },
    {
      time: 0.13,
      animation: [
        ["fade", [1]],
        ["move", [0, 0]],
        ["zoom", [1]],
      ],
    },
    {
      time: 0.4,
      animation: [
        ["fade", [1]],
        ["move", [0, 0]],
        ["zoom", [1]],
      ],
    },
    {
      time: 0.45,
      animation: [
        ["fade", [1]],
        ["move", [0, 0]],
        ["zoom", [1.5]],
      ],
    },
    {
      time: 2,
      animation: [
        ["fade", [1]],
        ["move", [0, 0]],
        ["zoom", [1.5]],
      ],
    },
  ];

  const wjazdy = useCallback(
    (initPosLeft = 200, initPosTop = 200, delay = 0.1) => {
      return [
        {
          time: 0,
          animation: [
            ["fade", [0]],
            ["move", [initPosLeft, initPosTop]],
          ],
        },
        {
          time: 0.35 + delay,
          animation: [
            ["fade", [0]],
            ["move", [initPosLeft, initPosTop]],
          ],
        },
        {
          time: 0.4 + delay,
          animation: [
            ["fade", [1]],
            ["move", [0, 0]],
          ],
        },
        {
          time: 1,
          animation: [
            ["fade", [1]],
            ["move", [0, 0]],
          ],
        },
      ];
    },
    []
  );

  const disappearInFirstStg = [
    {
      time: 0,
      animation: [
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0.2,
      animation: [
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0.28,
      animation: [
        ["fade", [0]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["fade", [0]],
        ["move", [0, 0]],
      ],
    },
  ];

  const profits = [
    <Profit
      keyframes={wjazdy(200, 200, 0.1)}
      video={require("./v_admin.mp4")}
      title="Przejrzysty panel zarządzania"
      descr={
        <>
          Bezstresowo i w każdej chwili przejmujesz kontrolę nad swoim biznesem.
          Regularne raporty na telefon to coś, co pokochasz.
        </>
      }
    ></Profit>,
    <Profit
      keyframes={wjazdy(-200, 200, 0.1)}
      video={require("./v_posd.mp4")}
      title="Nowoczesny, korzystny cenowo sprzęt"
      descr={
        <>
          Ten sprzęt zadba o to, aby Twój biznes i Twoi goście czuli się jak
          najlepiej. Oferuj promocje, sprzedawaj tam, gdzie jeszcze tego nie
          robiłeś.
        </>
      }
    ></Profit>,
    <Profit
      keyframes={wjazdy(200, -200, 0.1)}
      video={require("./v_pos.mp4")}
      title="Program POS"
      descr={
        <>
          Niezależny i działający w pełnej harmonii między lokalem i Twoją
          Chmurą Rapidshop. Nie przestaje działać, kiedy masz problemy z
          zasięgiem Internetu.
        </>
      }
    ></Profit>,
    <Profit
      keyframes={wjazdy(-200, -200, 0.1)}
      video={require("./v_ai.mp4")}
      title="Sztuczna inteligencja"
      descr={
        <>
          Wprowadź jej supermoce do Twojego lokalu! Pozwól sztucznej
          inteligencji zadbać o Twoich gości, a Tobie - zarządzać lokalem.
        </>
      }
    ></Profit>,
  ];

  return (
    <ScrollPage
      page={page}
      length={3}
      className={slideStyle.container}
      style={{ background: "#FFF" }}
      noOverflowHidden
    >
      <div style={{position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', bottom: '100%', left: 0, right: 0, margin: 'auto', width: '260px', height: '20px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', background: '#e0e0e0'}}>
        <div style={{height: '2px', background: '#bebebe', width: '50px'}}></div>
      </div>
      <Animator
        keyframes={overlayKeyframes}
        tag="div"
        className={slideStyle.containerOverlay}
      />
      <Animator
        keyframes={headerKeyframes}
        tag="div"
        className={slideStyle.containerAnimatedContent}
      >
        <div className={slideStyle.topPart}>
          <Animator
            keyframes={disappearInFirstStg}
            className={slideStyle.topPartContent}
          >
            <div style={{ fontSize: "0.5em" }}>Stworzyliśmy od podstaw</div>
            <b style={{ fontSize: "0.6em" }}>nowy ekosystem dla Twojej restauracji i detalu</b>
          </Animator>

          <div
            className={slideStyle.profitRow}
            style={{
              bottom: "40px",
            }}
          >
            {profits[0]}
            {profits[1]}
          </div>
        </div>

        <div className={slideStyle.sidePart}>
          <Animator
            tag="div"
            keyframes={wjazd}
            className={slideStyle.sidePartBg}
          >
            <img src={require("./blitz.png")} alt="Blitz" />
          </Animator>
        </div>

        <div className={slideStyle.bottomPart}>
          <Animator
            keyframes={disappearInFirstStg}
            className={slideStyle.bottomPartContent}
          >
            <div>
              dzięki któremu zyskasz dodatkowe przychody, nowych Klientów,
            </div>
            <div style={{ fontWeight: "bold" }}>
              a nowo pozyskanych Klientów zachęcisz do częstych powrotów!
            </div>
          </Animator>

          <div className={slideStyle.profitRow} style={{ top: "40px" }}>
            {profits[2]}
            {profits[3]}
          </div>
        </div>
      </Animator>
    </ScrollPage>
  );
};

export default Offer;
