import slideStyle from "./Solution.module.scss";
import Badge from "../../Badge";
import kiosk1 from "../../assets/kiosk1.jpg";

const ElementKiosk = () => {
  return (
    <div className={slideStyle.section}>
      <div className={slideStyle.description}>
        <h2>Kioski samoobsługowe z opcją agenta AI</h2>
        <div className={slideStyle.tips}>
          <Badge>Ze sztuczną inteligencją</Badge>

          <Badge>Dla wzrostu obrotów</Badge>
        </div>
        <div>
          <div style={{ marginBottom: "16px" }}>
            Pomyśl o tym, <b>ile mógłbyś więcej mieć Klientów</b>, jeśli
            zapewniłbyś im możliwość zakupu Twoich produktów w chwilach, w
            których się nad tym zastanawiają.
          </div>
          <div style={{ marginBottom: "10px" }}>
            Pomyśl o tym,{" "}
            <b>o ile więcej osób zrobi u Ciebie większe zamówienie </b>
            dzięki rekomendacjom i rabatom dostępnych w naszym kiosku przez cały
            czas działania Twojego lokalu. <b>Ile klentów wróci do Kiosku z dopracowanym UI.</b>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <b>
              Zlikwiduj bariery językowe - nasz Kiosk obsługuje wiele języków,
              potrafi także mówić w języku Twojego Klienta.{" "}
            </b>
            Kiosk możesz umieścić na stojaku podłogowym lub dyskretnie
            wkomponować na blacie w Twoim lokalu. Klienci cenią nasze kioski za
            komfort, który pozwala im w spokoju zapoznać się z ofertą. Dzięki
            inteligentnym funkcjom sprzedaży i intrygującym propozycjom
            widocznym na ekranie, zachęcają do częstszego wyboru i zakupu
            większej ilości pozycji z menu.
          </div>
          <div className={slideStyle.ctaRow}>
            <a
              className={slideStyle.cta}
              href="/kioski-samoobslugowe-z-chatem-gpt"
            >
              Dowiedz się więcej
            </a>
          </div>
        </div>
      </div>
      <div className={slideStyle.illustration}>
        <img src={kiosk1} alt="Kiosk z AI" />
      </div>
    </div>
  );
};

export default ElementKiosk;
