import React from "react";
import style from "./App.module.scss";

const Footer = () => {
  return (
    <section id="contact" className={style.contact}>
      <div className={style.copy}>
        <div className={style.copyCol}>
          <b>© 2025 APPIDEA - Rapid Development Company</b>
          <br />
          ul. Chmielna 73
          <br />
          00-801 Warsaw, Poland
          <br />
        </div>
        <div className={style.copyCol}>
          We build modern applications for web, mobile and IoT. <br /> Let's
          talk about your project!<br />
          <a href="/rs-policy-privacy.pdf" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
