import React, { useCallback, useMemo, useContext, useEffect } from "react";
import style from "../../App.module.scss";
import { restMargins } from "../../animatorUtils";
import { Animator, ScrollPage, ViewportContext } from "react-scroll-motion";
import slideStyle from "./Solution.module.scss";

const buttonStyle = {
  padding: "20px",
  borderLeftColor: "#6ac7ba",
  borderTopColor: "#6ac7ba",
  borderBottomColor: "rgb(22, 187, 231)",
  borderRightColor: "rgb(22, 187, 231)",
  borderWidth: "10px",
  margin: "20px",
};

const profits = {
  padding: "20px",
  fontSize: "1.3em",
  margin: "10px",
  width: "200px",
  background: "#5d5d5d",
  color: "#FFF",
  fontWeight: "bold",
};

const Solution = ({page, children}) => {
  const [lang, setLang] = React.useState("pl");
  const viewport = useContext(ViewportContext);

  const [mgLeft] = restMargins(387, 101, viewport);
  const containersWidth = viewport.width < 1300 ? viewport.width : 1300;

  const headerKeyframes = [
    {
      time: -0.001,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 0]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [...restMargins(containersWidth, 768, viewport), 1]],
        ["fade", [1]],
        ["move", [0, 0]],
      ],
    },
  ];

  
  const { width } = window.screen;
  const isSmall = width < 600;

  return (
    <ScrollPage
      page={page}
      length={isSmall ? 2 : 1}
      className={slideStyle.container}
      style={{
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className={slideStyle.descContainer}
        style={{ width: `${containersWidth - 32}px` }}
      >
        {children}
      </div>
    </ScrollPage>
  );
};

export default Solution;
