import React from "react";
import style from "./App.module.scss";

const ContactIframe = () => {
  const iframeRef = React.useRef();

  return (
    <a
      href="https://eepurl.com/i1SNeA"
      className={style.cta}
    >
      FORMULARZ KONTAKTOWY
    </a>
  );
};

export default ContactIframe;
