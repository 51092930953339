import slideStyle from "./Solution.module.scss";
import Badge from "../../Badge";
import cloud from "../../assets/cloud2.png"

const ElementTabletop = () => {
  return (
    <div className={slideStyle.section}>
      <div className={slideStyle.description}>
        <h2>Rapidshop Cloud</h2>
        <div className={slideStyle.tips}>
          <Badge>Sprzedaż LIVE w jednym panelu</Badge>
          <Badge>Sprytny kreator menu</Badge>
          <Badge>Zarządzanie urządzeniami</Badge>
          <Badge>Raporty</Badge>
        </div>
        <div style={{ marginTop: "30px" }}>
          <div style={{ marginBottom: "16px" }}>
            W Twoim nowym panelu Rapidshop Cloud znajdziesz wszystko, co
            potrzebujesz, aby zarządzać swoim biznesem. Sprawdzisz sprzedaż na
            żywo, zobaczysz, co zamawiają Twoi klienci, a także jakie dania
            cieszą się największym zainteresowaniem. Wszystko to w jednym
            miejscu, z dowolnego urządzenia, w dowolnym miejscu.
            Z kanałów online i offline - raporty z Kiosku, Kas, Stolików, a
            także z dostaw - wszystko w jednym miejscu.
          </div>
          <div style={{ marginBottom: "16px" }}>
            Twórz swoje menu niczym artysta w naszym przełomowym edytorze menu.
            Nie tylko dodawaj dania, ale organizuj je w foldery, przeplataj
            reklamami Twoich promocji, a nawet filmami wideo.
          </div>
          <div style={{ marginBottom: "16px" }}>
            Zarządzaj swoimi urządzeniami z poziomu panelu. Zdalnie restartuj
            kioski, aktualizuj menu, a nawet zablokuj wybrane dania.
            Steruj drukarkami i monitorami
          </div>
          <div className={slideStyle.ctaRow}>
            <a className={slideStyle.cta} href="/chmurowy-system-zarzadzania-sprzedaza">
              Dowiedz się więcej
            </a>
          </div>
        </div>
      </div>
      <div className={slideStyle.illustration}>
        <img src={cloud} alt="Chmura" />
      </div>
    </div>
  );
};

export default ElementTabletop;
