import slideStyle from "./InfoPage.module.scss";
import ContactAppendix from "./ContactAppendix";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Magia AI w Twojej restauracji"
        title="Rapidshop AI"
        attachStars
      />

      <div className={slideStyle.section} style={{ marginTop: "5px" }}>
        <div className={slideStyle.heading}>
          <h1>
            Zrób więcej i obsłuż lepiej
            <br />
            dzięki magii sztucznej inteligencji w Twoim POS!
          </h1>
        </div>

        <p>
          Automatyzuj procesy na Twoim zapleczu i przy zarządzaniu restauracją.
        </p>
        <ContactAppendix />
      </div>
    </>
  );
};

export default KioskAI;
