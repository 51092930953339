import React from "react";
import style from "../../App.module.scss";
import { Logo } from "../Logo";
import ContactIframe from "../../ContactIframe";

const SolutionAmos = () => {
  return (
    <div
      style={{
        position: "relative",
        zIndex: 999,
        backgroundColor: "#f3f3f3",
      }}
    >
      <section className={style.pageInner} style={{ marginBottom: 0 }}>
        <div className={style.heading}>
          <span>
            Rozpocznij techniczną rewolucję i zacznij zwiększać swój zysk
          </span>
          <h2>Porozmawiajmy!</h2>
        </div>

        <p style={{ marginBottom: "10px", marginTop: "40px" }}>
          Pozostaw nam Twoje dane kontaktowe. Twój dedykowany doradca
          zaproponuje termin na krótkie spotkanie online, na którym pokażemy Ci,{" "}
          <strong>
            jak nowe technologie mogą zwiększyć zysk Twojej restauracji
          </strong>
          .
        </p>
        <p style={{ marginTop: "0", marginBottom: "10px" }}>
          Wolisz spotkać się z nami na żywo? Nasze biuro znajduje się dwa kroki
          od warszawskiego Dworca Centralnego. Umówimy spotkanie, na którym
          będziesz mógł własnoręcznie przetestować system Rapidshop.
        </p>
        <p style={{ marginTop: "0" }}>
          Wolisz wysłać maila? Napisz do nas:{" "}
          <a href="mailto:kontakt@rapidshop.pl">kontakt@rapidshop.pl</a>.
        </p>

        <ContactIframe />

        <Logo />
      </section>
    </div>
  );
};

export default SolutionAmos;
