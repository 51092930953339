import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";
import LandingPage from "./LandingPage";
import Navbar from "./Navbar";
import style from "./App.module.scss";
import { ScrollContainer } from "react-scroll-motion";
import KioskiAI from "./InfoPages/KioskiAI";
import SafePOS from "./InfoPages/SafePOS";
import Cloud from "./InfoPages/Cloud";
import Pricing from "./InfoPages/Pricing";
import Legal from "./InfoPages/Legal";
import POSApp from "./InfoPages/POSApp";
import DiscountSystem from "./InfoPages/DiscountSystem";
import KDS from "./InfoPages/KDS";
import Footer from "./Footer";
import ContactForm from "./InfoPages/ContactForm";
import RapidshopAI from "./InfoPages/RapidshopAI";
import Devices from "./InfoPages/Devices";
import Consent from "./Consent";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/kioski-samoobslugowe-z-chatem-gpt",
      element: <KioskiAI />,
    },
    {
      path: "/aplikacja-sprzedazowa-android",
      element: <POSApp />,
    },
    {
      path: "/system-ekranow-kuchennych",
      element: <KDS />,
    },
    {
      path: "/system-promocji-i-rabatow",
      element: <DiscountSystem />,
    },
    {
      path: "/latwe-zarzadzanie-sprzetem",
      element: <SafePOS />,
    },
    {
      path: "/chmurowy-system-zarzadzania-sprzedaza",
      element: <Cloud />,
    },
    {
      path: "/cennik",
      element: <Pricing />,
    },
    {
      path: "/legal",
      element: <Legal />,
    },
    {
      path: "/ai-w-twoim-punkcie-sprzedazy",
      element: <RapidshopAI />,
    },
    {
      path: "/sprzet",
      element: <Devices />,
    },
    {
      path: "/kontakt",
      element: <ContactForm />,
    },
  ]);

  return (
    <ScrollContainer>
      <div className={style.app}>
        <Navbar />
        <RouterProvider router={router} />

        <Footer />
      </div>
      <Consent />
    </ScrollContainer>
  );
}

export default App;
