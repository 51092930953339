import slideStyle from "./Solution.module.scss";
import Badge from "../../Badge";
import pos from "../../assets/pos.jpg";
import classNames from "classnames";

const ElementHandheld = () => {
  return (
    <div className={slideStyle.section}>
      <div
        className={classNames(
          slideStyle.illustration,
          slideStyle.illustrationLeft
        )}
      >
        <img src={pos} alt="Kiosk z AI" />
      </div>
      <div className={slideStyle.description}>
        <h2>Terminale ręczne i nablatowe Rapidshop</h2>
        <div className={slideStyle.tips}>
          <Badge>Wsparcie dla personelu</Badge>
          <Badge>Usprawnienie obsługi</Badge>
        </div>
        <div>
          <div style={{ marginBottom: "16px" }}>
            Eleganckie, szybkie i intuicyjne terminale Rapidshop to ergonomia,
            wydajność i niezawodność w jednym. Pisząc oprogramowanie na nasze
            terminale, przyjęliśmy zasadę, że nie tworzymy absurdalnych
            procedur. Zamiast wielu dziwnych i przeróżnych klawiszy, nasze
            terminale same prowadzą Twoją załogę, odciążając Cię od konieczności
            ciągłego szkolenia nowych pracowników.
          </div>
          <div style={{ marginBottom: "16px" }}>
            W skrócie, te rewolucyjne - a za razem przystępne cenowo urządzenia
            przyspieszają zamówienia i płatności. Twoja załoga sprawnie
            obsługuje sale, zawsze wie, co polecić Klientowi, a to wszystko w
            efekcie prowadzi do <b>wyraźnego wzrostu obrotów</b>.
          </div>
          <div>
            Ponadto nasze terminale współpracują w jednym systemie z naszymi
            kioskami, co powoduje, że możesz tak samo łatwo zarządzać
            zamówieniami z kiosku, jak i z terminala. Wszystko to po to, by Twoi
            klienci byli zadowoleni, a Ty mógł skupić się na rozwijaniu swojego
            biznesu.
          </div>
          <div className={slideStyle.ctaRow}>
            <a className={slideStyle.cta} href="/aplikacja-sprzedazowa-android">
              Dowiedz się więcej
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementHandheld;
