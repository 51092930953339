import slideStyle from "./InfoPage.module.scss";
import ContactAppendix from "./ContactAppendix";
import Badge from "../Badge";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Ergonomiczna, przyjazna i w pełni mobilna"
        title="Aplikacja sprzedażowa"
        hints={[
          "Na urządzeniu na blacie",
          'Na urządzeniu "do ręki"',
          "Centrum sterowania, niezależnie od miejsca",
        ]}
      />
      <div className={slideStyle.section}>
        <div className={slideStyle.sectionContent}>
          <div>
            <h2 className={slideStyle.centered}>
              Nasza aplikacja POS działa tak samo, czy to na blacie, czy w ręku
              Twojego kasjera. Koniec z bieganiem do baru!
            </h2>
            <div className={slideStyle.embeddedImg}>
              <img src={require("../assets/pos-paper.jpg")} alt="POS" />
            </div>
            <p>
              Teraz Twoja załoga może działać efektywnie, eliminując zbędne
              zamieszanie. Nasza nowoczesna aplikacja POS nie tylko ułatwia
              codzienne operacje, ale również stanowi klucz do zwiększenia
              sprzedaży i podniesienia poziomu zadowolenia Klientów.
            </p>
            <p>
              Wyjątkowy, ergonomiczny design i intuicyjny interfejs pozwalają
              Twoim pracownikom obsługiwać zamówienia w mgnieniu oka. To
              bezpośrednio przekłada się na pozytywne doświadczenia Klientów
              oraz znaczący wzrost obrotów.
            </p>
            <h2>
              Wykorzystaj ekran Klienta aby wzmocnić więź i budować lojalność
            </h2>
            <p>
              Ekran Klienta to znacznie więcej niż tylko podgląd rachunku.
              Uzupełnij działania swojej załogi, wyświetlając spersonalizowane
              oferty, promocje oraz informacje o produktach. Zainspiruj
              dodatkowe zakupy, stwórz powody do powrotu i zaskocz swoich
              Klientów unikalnym doświadczeniem.
            </p>
            <h2>Fiskalizacja i płatności</h2>
            <p>
              Fiskalizacji rachunków otwartych w aplikacji sprzedażow dokonasz
              bez problemu na drukarce fiskalnej (aktualnie obsługujemy drukarki
              POSNET), a rozwiązania płatnicze i obsługę kart dostarcza firma{" "}
              <b>eservice</b>. Dostawcy urządzeń fiskalnych i płatniczych, z
              którymi współpracujemy to <b>liderzy na rynku</b>, co gwarantuje
              stabilność i niezawodność.
            </p>
          </div>

          <div
            className={slideStyle.heading}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <h1>
              Zrób więcej i obsłuż lepiej
              <br />
              dzięki magii sztucznej inteligencji w Twoim POS!
            </h1>
          </div>
        </div>

        <ContactAppendix />
      </div>
    </>
  );
};

export default KioskAI;
