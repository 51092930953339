import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./Navbar.module.scss";
import logo from "../assets/logo.svg";
import {
  FaBars,
  FaChevronDown,
  FaPhone,
  FaChevronRight,
  FaTimes,
} from "react-icons/fa";
import {} from "react-router-dom";
import { SessionContext } from "@appidea/rs.bs.customers.react";
import { PagesRegistryContext, Animator } from "react-scroll-motion";
import {
  logoBarKeyframes,
  NavbarBottomBarAnimation,
  navbarBottomKeyframes,
} from "./Navbar.animation";
import NavbarButton from "./NavbarButton";
import { LogoText } from "../LandingPage/Logo";
import Possibilities from "./Possibilities";

const MobileNavbarButtton = ({ children, ...props }) => {
  return (
    <button className={style.mobileMenuItem} {...props}>
      {children} <FaChevronRight />
    </button>
  );
};

const AnimationWrapper = ({ children }) => {
  const { registerAnimation } = useContext(PagesRegistryContext);

  useMemo(() => {
    registerAnimation("navbarBottomBar", NavbarBottomBarAnimation);
  }, [registerAnimation]);

  return (
    <Animator
      relativeTo="viewport"
      keyframes={logoBarKeyframes}
      className={style.headerLogoBar}
    >
      {children}
    </Animator>
  );
};

const NoAnimationWrapper = ({ children }) => {
  return <div className={style.headerLogoBar}>{children}</div>;
};

const Navbar = () => {
  const [setLoginOpen] = useState(false);
  const { user } = useContext(SessionContext);
  const { logout } = useContext(SessionContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const [isMainPage, setIsMainPage] = useState(false);
  useEffect(() => {
    const listener = () => {
      setIsMainPage(window.location.pathname === "/");
    };

    listener();
    window.addEventListener("popstate", listener);
    return () => window.removeEventListener("popstate", listener);
  }, []);

  const BarWrapper = isMainPage ? AnimationWrapper : NoAnimationWrapper;

  return (
    <>
      <header className={style.navbar}>
        <BarWrapper>
          <div className={style.headerLogoBarInner}>
            <LogoText />
          </div>
        </BarWrapper>
        <div className={style.headerInner}>
          <a href="/" className={style.logoZone}>
            
          </a>
          <button
            className={style.mobileMenuButton}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <FaBars size={30} />
          </button>
          <div className={style.buttons}>
            <div className={style.buttonsLeft}>
              <Possibilities isMainPage={isMainPage} />
              <NavbarButton
                isMainPage={isMainPage}
                icon={FaChevronDown}
                onClick={() => (window.location = "/cennik")}
              >
                Cennik
              </NavbarButton>
              <NavbarButton
                isMainPage={isMainPage}
                icon={FaChevronDown}
                onClick={() => (window.location = "/sprzet")}
              >
                Sprzęt
              </NavbarButton>
            </div>

            <div className={style.buttonsRight}>
              <NavbarButton
                isMainPage={isMainPage}
                onClick={() => (window.location = "/kontakt")}
                icon={FaPhone}
              >
                Kontakt z nami
              </NavbarButton>
            </div>
          </div>
        </div>

        <Animator
          keyframes={navbarBottomKeyframes}
          relativeTo="viewport"
          className={style.navbarBottomBar}
        />
      </header>

      {menuOpen && (
        <div className={style.mobileMenu}>
          <MobileNavbarButtton
            onClick={() =>
              (window.location = "/chmurowy-system-zarzadzania-sprzedaza")
            }
          >
            Rapidshop Cloud
          </MobileNavbarButtton>
          <MobileNavbarButtton
            onClick={() =>
              (window.location = "/kioski-samoobslugowe-z-chatem-gpt")
            }
          >
            Kioski samoobsługowe
          </MobileNavbarButtton>
          <MobileNavbarButtton
            onClick={() => (window.location = "/ai-w-twoim-punkcie-sprzedazy")}
          >
            Rapidshop AI
          </MobileNavbarButtton>
          <MobileNavbarButtton
            onClick={() => (window.location = "/aplikacja-sprzedazowa-android")}
          >
            Aplikacja POS
          </MobileNavbarButtton>
          <MobileNavbarButtton
            onClick={() => (window.location = "/system-ekranow-kuchennych")}
          >
            Rapid KDS
          </MobileNavbarButtton>
          <MobileNavbarButtton
            onClick={() => (window.location = "/system-promocji-i-rabatow")}
          >
            Promocje i rabaty
          </MobileNavbarButtton>
          <MobileNavbarButtton
            onClick={() => (window.location = "/latwe-zarzadzanie-sprzetem")}
          >
            Zarządzanie sprzętem
          </MobileNavbarButtton>
          <MobileNavbarButtton onClick={() => (window.location = "/cennik")}>
            Cennik
          </MobileNavbarButtton>
          <MobileNavbarButtton onClick={() => (window.location = "/sprzet")}>
            Sprzęt
          </MobileNavbarButtton>
          <MobileNavbarButtton onClick={() => (window.location = "/kontakt")}>
            Kontakt z nami
          </MobileNavbarButtton>

          <button
            className={style.mobileMenuClose}
            onClick={() => setMenuOpen(false)}
          >
            <FaTimes size={30} />
          </button>
        </div>
      )}
    </>
  );
};

export default Navbar;
