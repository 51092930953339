import slideStyle from "./InfoPage.module.scss";
import ContactAppendix from "./ContactAppendix";
import classNames from "classnames";
import ComparisonTable from "./ComparisonTable";

import appStyle from "../App.module.scss";

const Pricing = () => {
  return (
    <div className={slideStyle.section}>
      <div className={slideStyle.heading}>
        <div style={{margin: '40px 0'}}>
          <div className={appStyle.heading}>
            <span>Wybór systemu Rapidshop to inwestycja w przyszłość</span>
            <h2>zyskujesz nowoczesne technologie sprzedażowe</h2>
          </div>
        </div>

        <div className={slideStyle.benefit}>
          <div>
            <h2>Wzrost zysków</h2>
            <p>
              Oszczędzaj czas i pieniądze. Nasz system pomoże Ci zmniejszyć
              zbędne koszty dzięki optymalizacji zarządzania zapasami oraz
              minimalizacji błędów ludzkich podczas obsługi zamówień i
              płatności.
            </p>
          </div>
          <div>
            <img
              src={require("./profits/money-count.png")}
              alt="Wzrost zysków"
            />
          </div>
        </div>

        <div className={classNames(slideStyle.benefit, slideStyle.benefitRev)}>
          <div>
            <h2>Prosty w użytkowaniu, łatwy do pokochania</h2>
            <p>
              Dzięki intuicyjnemu interfejsowi Twój personel stanie się
              ekspertem w mgnieniu oka. Szkolenie zajmuje minuty, a nie godziny,
              co pozwala Ci skupić się na doskonałej obsłudze Klientów.
            </p>
          </div>
          <div>
            <img
              src={require("./profits/intuitive.png")}
              alt="Prosty w użytkowaniu"
            />
          </div>
        </div>

        <div className={slideStyle.benefit}>
          <div>
            <h2>Dane w czasie rzeczywistym na wyciągnięcie ręki</h2>
            <p>
              Podejmuj świadome decyzje dzięki natychmiastowemu dostępowi do
              raportów sprzedaży, preferencji Klientów i wyników pracy zespołu.
              Prześcignij konkurencję wiedząc co działa najlepiej dla Twojego
              biznesu.
            </p>
          </div>
          <div>
            <img
              src={require("./profits/results.png")}
              alt="Dane w czasie rzeczywistym"
            />
          </div>
        </div>

        <div className={classNames(slideStyle.benefit, slideStyle.benefitRev)}>
          <div>
            <h2>Mniej przestojów, większy spokój ducha dla Ciebie</h2>
            <p>
              Nasz system POS charakteryzuje się niższymi kosztami utrzymania
              dzięki nowoczesnym i przystępnym cenowo urządzeniom. Wszystko jest
              zaprojektowane z myślą o trwałości i niezawodności, co oznacza
              mniej przestojów i większą satysfakcję z pracy Twojego zespołu.
            </p>
          </div>
          <div>
            <img src={require("./profits/peace.png")} alt="Pokój w duchu" />
          </div>
        </div>

        <h1>W końcu - dopasowany do Ciebie</h1>
        <p>
          Nasze elastyczne plany cenowe są stworzone z myślą o firmach każdej
          wielkości. Pomóż usprawnić operacje i wynieś swoją restaurację na
          wyższy poziom.
          <br />
          <strong>
            Zostaw nam swój kontakt, zaplanujemy rozmowę, na której wybierzemy
            idealny wariant wdrożenia!
          </strong>
        </p>

        {/* <ComparisonTable /> */}

        <div>
          <div
            style={{
              fontSize: "6em",
              fontWeight: "bold",
              textAlign: "center",
              margin: "20px 0",
            }}
          >
            99 PLN
          </div>
          <div>
            netto za m-c - od takiej kwoty zaczynają się nasze plany
            subskrypcji. Już w takim pakiecie dostajesz sporo!
          </div>
          <div>Napisz i dowiedz się więcej.</div>
        </div>

        <ContactAppendix />
      </div>
    </div>
  );
};

export default Pricing;
