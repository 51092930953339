import slideStyle from "./InfoPage.module.scss";
import Badge from "../Badge";
import kiosk1 from "../assets/kiosk1.jpg";
import kiosk2 from "../assets/kiosk2.jpg";
import kiosk3 from "../assets/kiosk3.jpg";
import kiosk4 from "../assets/kiosk4.jpg";
import kiosk5 from "../assets/kiosk5.jpg";
import ContactAppendix from "./ContactAppendix";
import ReactFastMarquee from "react-fast-marquee";
import Heading from "./Heading";

const KioskAI = () => {
  return (
    <>
      <Heading
        subtitle="Poznaj nasze przełomowe"
        title="Kioski samoobsługowe"
        hints={[
          "Ze sztuczną inteligencją",
          "Dobry design i dopracowany UX",
          "Dla wzrostu obrotów",
        ]}
      />

      <ReactFastMarquee
        gradientColor={[0, 0, 0]}
        autoFill={true}
        style={{ margin: "30px 0", padding: 0 }}
      >
        <img
          alt="Kiosk z AI"
          style={{ height: "250px", float: "right", margin: "0 50px" }}
          src={kiosk1}
        />
        <img
          alt="Kiosk z AI"
          style={{ height: "250px", float: "right", margin: "0 50px" }}
          src={kiosk2}
        />
        <img
          alt="Kiosk z AI"
          style={{ height: "250px", float: "right", margin: "0 50px" }}
          src={kiosk3}
        />
        <img
          alt="Kiosk z AI"
          style={{ height: "250px", float: "right", margin: "0 50px" }}
          src={kiosk4}
        />
        <img
          alt="Kiosk z AI"
          style={{ height: "250px", float: "right", margin: "0 50px" }}
          src={kiosk5}
        />
      </ReactFastMarquee>

      <div className={slideStyle.section} style={{ marginTop: "5px" }}>
        <div>
          <h2 className={slideStyle.centered}>
            Kioski samoobsługowe to absolutny hit obecnych czasów. Dla Ciebie
            jest to szansa na większe przychody!
          </h2>
          <p>
            To szansa na dotarcie do nowych Klientów i zaproponowanie im swoich
            produktów w chwilach, w których się nad tym zastanawiają.
          </p>
          <p style={{ marginBottom: "10px" }}>
            To większe zamówienia, dzięki niezmiennie dobrym rekomendacjom,
            lepszej komunikacji Twoich rabatów i ofert.
          </p>
          <p>
            Nasz elegancki kiosk to połączenie nowoczesnego designu,
            funkcjonalności i prostoty, które idealnie wkomponuje się w każdą
            przestrzeń sprzedażową. Możesz go umieścić na wyeksponowanym
            postumencie lub dyskretnie wkomponować na blacie w Twoim lokalu.
            Klienci cenią nasze kioski za komfort, który pozwala im w spokoju
            zapoznać się z ofertą. Dzięki inteligentnym funkcjom sprzedaży i
            intrygującym propozycjom widocznym na ekranie, zachęcają do
            częstszego wyboru i zakupu większej ilości pozycji z menu.
          </p>
        </div>

        <div className={slideStyle.embeddedImg}>
          <img alt="Kiosk z AI" src={kiosk1} />
        </div>

        <div>
          <h2>
            Nasze kioski działają płynniej, szybciej i dostarczają Klientowi
            więcej możliwości.
          </h2>
          <p>
            Posiadanie kiosku samo w sobie nie jest żadną sztuką. Kluczem do
            sukcesu jest to, jak ten kiosk funkcjonuje, jakie doświadczenia
            oferuje Twoim Klientom i jaką wygodę zapewnia. W dzisiejszych
            czasach, kiedy technologia jest wszechobecna, użytkownicy oczekują
            intuicyjnego i przyjaznego interfejsu.{" "}
            <b>Kioski Rapidshop dostarczają taki interfejs.</b> Z łatwym w
            obsłudze systemem, klienci szybciej znajdują to, czego szukają, co
            zwiększa ich satysfakcję i skłania do powrotów. Natomiast{" "}
            <b>możliwość proponowania Klientom dodaktów do zamówienia</b>{" "}
            bezpośrednio w Kiosku może być łatwym sposobem na zwiększenie Twoich
            przychodów.
          </p>
          <p>
            Interfejs użytkownika jest jednak tylko częścią większej całości,
            jaką jest doświadczenie użytkownika (UX). To nie tylko to, jak coś
            wygląda, ale przede wszystkim to, jak działa. Dobra architektura
            informacji, płynna nawigacja i szybki czas reakcji to tylko niektóre
            aspekty, które wpływają na pozytywne doświadczenie Twojego Klienta.
          </p>
          <p>
            Jeżeli ktoś ma pozytywne doświadczenia z korzystania z kiosku, o
            wiele chętniej wróci do niego w przyszłości.{" "}
            <b>
              Poczucie, że jesteśmy w stanie szybko i bezproblemowo załatwić to,
              co chcemy, budzi pozytywne emocje
            </b>{" "}
            – a to właśnie emocje są tym, co przyciąga nas do produktów i usług.
            Stawiając na dobrą jakość UX, inwestujesz{" "}
            <b>
              w przyszłość swojego biznesu oraz zadowolenie swoich Klientów.
            </b>
          </p>
        </div>

        <div
          className={slideStyle.heading}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <h1>
            Znasz to z telefonu. Naciskasz, mówisz...{" "}
            <span style={{ fontSize: "1.3em" }}>i jest!</span>
            <br />
            Pomyśl, że Twój kiosk może być tak samo inteligentny!
          </h1>
          <img
            alt="Kiosk z AI"
            style={{ width: "400px", float: "left", margin: "0 20px 20px 0" }}
            src={kiosk2}
          />
        </div>

        <div>
          <strong>
            Koniec z ograniczeniami. Pozwól Twoim Klientom rozmawiać z kioskiem
            i zwiększaj sprzedaż!
          </strong>

          <p>
            Kiosk z AI to nie tylko nowoczesne urządzenie, ale także nowe
            możliwości dla Twojego biznesu. Dzięki sztucznej inteligencji{" "}
            <b>
              Twój kiosk może sam, proaktywnie podjąć interakcję z Twoim
              Klientem.
            </b>
          </p>
          <p>
            Kiosk z AI to również sposób na dotarcie do wiekszej liczby Klientów
            niż w standardowym Kiosku. Asystent głosowy oparty o najnowsze
            modele językowe sztucznej inteligencji ogromnie ułatwia komunikację
            z każdym Klientem - możesz otworzyć się na nowe grupy Klientów,
            które wcześniej nie korzystały z Twoich kiosków.
          </p>
        </div>

        <div
          className={slideStyle.heading}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <h1>
            Pożegnaj barierę językową i wyróżnij się <br />
            wyjątkową obsługą turystów w Twoim lokalu!
          </h1>
        </div>
        <div>
          <p>
            Prowadzisz biznes w miejscu, gdzie często pojawiają się turyści? A
            może Twoi Klienci to osoby, które nie czują się pewnie w języku
            polskim? Kiosk z AI to idealne rozwiązanie, które pozwala na łatwą i
            intuicyjną komunikację z każdym Klientem. Dzięki asystentowi
            głosowemu, każdy Klient może w prosty sposób złożyć zamówienie,
            otrzymać informacje o produktach i skorzystać z promocji. To nie
            tylko ułatwia komunikację, ale także pozwala na zwiększenie
            sprzedaży i przyciągnięcie nowych Klientów.
          </p>
          <p>
            Nie musisz sam wprowadzać opisów produktów w różnych językach - nasz
            asystent głosowy oparty o AI{" "}
            <b>
              jest w stanie przetłumaczyć informacje na język Klienta w
              okamgnieniu
            </b>{" "}
            i zależnie od bieżącej potrzeby, co pozwala na sprawną i
            bezproblemową komunikację.{" "}
            <b>
              Dostarczasz Klientowi wysoki poziom obsługi, nawet jeśli Twoi
              pracownicy nie znaja języka Klienta
            </b>
            , co otwiera nowe możliwości dla Twojego biznesu.
          </p>
        </div>

        <div style={{ textAlign: "center" }}>
          <h2>
            Rapid SAFEPOS - przewidywalność i cyberbezpieczeństwo w Kiosku
          </h2>
          <p>
            Dobry Kiosk to taki Kiosk, który zapewnia stabilność działania.{" "}
            <b>
              Każde z urządzeń samoobsługowych wymaga odpowiedniego zarządzania,
              aby mogło spełniać swoje funkcje w sposób bezpieczny i niezawodny
            </b>
            . Aby osiągnąć ten cel, proponujemy Państwu nasze rozwiązanie "Rapid
            SAFEPOS", które preinstalujemy na urządzeniach dostarczonych do
            Państwa. Dzięki niej każdy właściciel kiosku zyskuje pewność, że
            jego biznes <b>działa jak dobrze naoliwiona maszyna.</b>
          </p>
          <p>
            <a href="/latwe-zarzadzanie-sprzetem">
              Więcej informacji o rozwiązaniu Rapid SAFEPOS &gt;
            </a>
          </p>
        </div>
        <ContactAppendix />
      </div>
    </>
  );
};

export default KioskAI;
