import AIStars from "../../AIStars";
import style from "./Heading.module.scss";
import { FaChevronRight } from "react-icons/fa";

const Heading = ({ title, subtitle, hints, attachStars }) => {
  return (
    <div className={style.container}>
      <div className={style.containerLayer0}>
        <div className={style.containerWidth}>
          <div className={style["subtitle"]}>{subtitle}</div>
          <h1 className={style.title} style={{marginBottom: attachStars ? '0.1em' : undefined}}>{title}</h1>

          {attachStars && <AIStars />}

          {hints && (
            <div className={style.tips}>
              {hints.map((hint, index) => (
                <div key={index}>
                  <FaChevronRight />
                  <span>{hint}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={style.containerLayer1}>
        <div className={style.containerWidth}>
          <div className={style["subtitle"]}>{subtitle}</div>
          <h1 className={style.title} style={{marginBottom: attachStars ? '0.1em' : undefined}}>{title}</h1>

          {attachStars && <AIStars />}

          {hints && (
            <div className={style.tips}>
              {hints.map((hint, index) => (
                <div key={index}>
                  <FaChevronRight />
                  <span>{hint}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Heading;
