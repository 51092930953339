import React, {forwardRef} from 'react';
import styles from './Navbar.module.scss';
import { Animator } from 'react-scroll-motion';
import {navbarIconKeyframes, navbarButtonKeyframes} from "./Navbar.animation";

const NavbarButton = forwardRef(({icon: Icon, isMainPage, children, ...props}, ref) => {
  return (
    <div ref={ref}>
      {isMainPage && (
        <Animator tag="button" relativeTo="viewport" keyframes={navbarButtonKeyframes} className={styles.button} {...props}>
          <Animator relativeTo="viewport" keyframes={navbarIconKeyframes} tag={"div"} size={20} className={styles.buttonContent}>
            <Icon className={styles.buttonIcon} />
            {children}
          </Animator>
        </Animator>
      )}
      {!isMainPage && (
        <button className={styles.button} {...props}>
          <Icon className={styles.buttonIcon} />
          {children}
        </button>
      )}
      
    </div>
  );
});

export default NavbarButton;
