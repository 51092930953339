import React from "react";
import slideStyle from "./InfoPage.module.scss";
import Badge from "../Badge";
import ContactAppendix from "./ContactAppendix";
import Heading from "./Heading";

const SafePOS = () => {
  return (
    <>
      <Heading
        subtitle="Rapid SAFE POS to aplikacja, która w końcu pozwoli na"
        title="Łatwe zarządzanie sprzętem"
        hints={[
          "Mniej nieprzewidzianych awarii",
          "Większe bezpieczeństwo",
          "Kontrola stanu urządzeń",
        ]}
      />
      <div className={slideStyle.section}>
        <p>
          Zarządzanie sprzętem może być wyzwaniem, ale "Rapid SAFE POS" to
          aplikacja, która upraszcza wszystko w mgnieniu oka.{" "}
          <b>
            To jak magiczna różdżka, która zamienia trudne w łatwe, a
            skomplikowane w proste.
          </b>{" "}
          Dzięki niej właściciel punktu sprzedaży lub placówki gastronomicznej
          nie muszą martwić się o techniczne zawiłości ani obawiać awarii. Z
          "Rapid SAFE POS" zyskujesz pełną kontrolę nad swoimi urządzeniami i
          możesz skupić się na tym, co naprawdę się liczy - zadowoleniu Klientów
          i rozwijaniu biznesu.
        </p>
        <p>
          Jednym z kluczowych atutów aplikacji "Rapid SAFEPOS" jest to, że{" "}
          <b>
            dzięki niej Twoi pracownicy i Klienci mają dostęp tylko do aplikacji
            związanych z ich pracą.
          </b>{" "}
          Oznacza to, że pracownicy nie mają możliwości przypadkowego zmienienia
          ustawień lub wprowadzenia modyfikacji, które mogłyby zaszkodzić
          urządzeniu. Dzięki temu{" "}
          <strong>
            zmniejszamy liczbę potencjalnych sytuacji gdzie musisz skontaktować
            się z serwisem.
          </strong>
        </p>
        <p>
          Dzięki aplikacji "Rapid SAFEPOS", masz lepszą kontrolę nad tym, jak
          działają Twoje urządzenia. Aplikacja umożliwia{" "}
          <b>śledzenie stanu urządzenia w czasie rzeczywistym</b>, niezależnie
          od rodzaju i modelu, co pozwala na szybszą reakcję
          w razie ewentualnych problemów. Ponadto, aktualizacje aplikacji
          sprzedażowej{" "}
          <b>
            nie wymagają wielu skomplikowanych kroków - wykonywane są jednym
            kliknięciem
          </b>
          . Jeżeli zajdzie potrzeba dodania funkcji do Twojego kiosku - odbywa
          się to błyskawicznie. To nie tylko zwiększa bezpieczeństwo całego
          systemu, ale także umożliwia lepsze zarządzanie sprzedażą, co w
          skuteczny sposób wpływa na ogólną wydajność biznesu.
        </p>
        <p>
          Korzyści płynące z aplikacji "Rapid SAFEPOS" przekładają się również
          na{" "}
          <b>redukcję kosztów związanych ze szkoleniem pracowników</b>. Dzięki
          uproszczonemu interfejsowi i ograniczonemu zakresowi możliwych
          działań, nauka obsługi urządzeń staje się znacznie łatwiejsza i mniej
          czasochłonna. W rezultacie pracodawca może zaoszczędzić zarówno czas,
          jak i środki finansowe, możesz przeznaczyć na inne ważne aspekty
          rozwijania swojego biznesu. Dzięki tym wszystkim elementom, kioski
          wyposażone w "Rapid SAFEPOS" stają się bardziej niezawodne, co pozwala
          utrzymać stabilność operacyjną i skoncentrować się na rozwoju firmy.
        </p>

        <ContactAppendix />
      </div>
    </>
  );
};

export default SafePOS;
