import React from "react";
import slideStyle from "../InfoPage.module.scss";

const TOC = () => {
  return (
    <div className={slideStyle.section}>
      <h1>Regulamin RAPIDSHOP</h1>
      <p style={{ marginBottom: "30px" }}>Wersja z dn. 1. marca 2025</p>

      <div style={{fontSize: "0.8rem"}}>
        <h2>Definicje</h2>
        <ul>
          <li>
            <strong>Usługodawca</strong> – firma Appidea.pl Mateusz Falkowski z
            siedzibą w (87-100) Toruniu, przy ul. 63. Pułku Piechoty 35 m. 3,
            posiadająca numer REGON 361902272, NIP 9562283982, która jest
            właścicielem oraz podmiotem zarządzającym Zbiorem Aplikacji
            Rapidshop, będąca stroną Umowy;
          </li>
          <li>
            <strong>Usługobiorca</strong> - przedsiębiorca prowadzący
            działalność gospodarczą lub spółka prawa handlowego, będąca stroną
            Umowy;
          </li>
          <li>
            <strong>Platforma</strong> – odpłatna usługa dostępna w sieci
            Internet w oparciu o PaaS - Platform as a Service - do której
            wszelkie prawa autorskie należą do Usługodawcy, o nazwie "Rapidshop
            Cloud", znajdująca się w domenie "rapidshop.pl" lub subdomenie tej
            domeny;
          </li>
          <li>
            <strong>Aplikacja POS</strong> - oprogramowanie pod postacią
            aplikacji mobilnej o nazwie "Rapidshop POS", do której wszelkie
            prawa autorskie należą do Usługodawcy;
          </li>
          <li>
            <strong>Aplikacja Rapid SAFEPOS</strong> - specjalna aplikacja
            mobilna o podwyższonych uprawnieniach użytkowania Urządzenia, do
            której wszelkie prawa autorskie należą do Usługodawcy;
          </li>
          <li>
            <strong>Aplikacje Ekosystemu</strong> - pozostałe systemy
            informatyczne, usługi w chmurze i aplikacje mobilne, dostępne
            poprzez sieci wewnętrzne Usługodawcy bądź poprzez sieć Internet,
            którymi operuje Usługodawca bądź jednostka upoważniona przez
            Usługobiorcę i które Usługodawca udostępnił do korzystania przez
            Usługobiorcę;
          </li>
          <li>
            <strong>Zbiór Aplikacji Rapidshop</strong> - zbiór składający się z:
            Platformy, Aplikacji POS, Aplikacji Rapid SAFEPOS i wszelkich
            Aplikacji Ekosystemu.
          </li>
          <li>
            <strong>Miejsce Sprzedaży</strong> - placówka handlowa zajmująca się
            w przeważającej części handlem detalicznym, z wyłączeniem handlu
            detalicznego pojazdami samochodowymi, w tym zajmująca się sprzedażą
            posiłków, należąca do Usługobiorcy;
          </li>
          <li>
            <strong>Urządzenie</strong> - sprzęt komputerowy klasy PC lub sprzęt
            elektroniczny pod kontrolą systemu operacyjnego Android, będący
            własnością Usługobiorcy, lub którego Usługobiorca jest posiadaczem
            na podstawie umowy najmu, dzierżawy, leasingu bądź innej umowy.
          </li>
          <li>
            <strong>Kasjer</strong> - osoba fizyczna zatrudniona przez
            Usługobiorcę lub wykonującą usługi na rzecz Usługobiorcy na
            podstawie stosownej umowy;
          </li>
          <li>
            <strong>Punkt Sprzedaży</strong> - pojedyncze Urządzenie spełniające
            niezbędne wymagania techniczne do uruchomienia Aplikacji POS,
            obsługiwane przez Kasjerów;
          </li>
          <li>
            <strong>Kiosk</strong> - pojedyncze Urządzenie spełniające niezbędne
            wymagania techniczne do uruchomienia Aplikacji POS, obsługiwane
            przez Klientów;
          </li>
          <li>
            <strong>Klient</strong> - Osoba przebywająca w Miejscu Sprzedaży,
            bądź mająca zamiar dokonania zakupu u Usługobiorcy;
          </li>
          <li>
            <strong>Użytkownik Kanałów Online</strong> - osoba fizyczna, która
            posiada dostęp do Kanałów Online,
          </li>
          <li>
            <strong>Kanał Online</strong> - aplikacja mobilna lub strona
            internetowa pozwalająca na zakładanie Rachunków na Platformie przez
            osoby fizyczne niepowiązane z Usługobiorcą, która może być
            instalowana bądź wykorzystywana na własnym sprzęcie,
          </li>
          <li>
            <strong>Baza Produktów</strong> – Produkty wprowadzone na Platformę
            przez Usługobiorcę lub jego pracowników, przypisane do Miejsca
            sprzedaży;
          </li>
          <li>
            <strong>Hasło Kasjera</strong> – ciąg znaków ustalonych przez
            Usługobiorcę, który służy do identyfikacji Kasjera;
          </li>
          <li>
            <strong>Multimedia</strong> - obrazy oraz nagrania wideo,
            przechowywane na serwerach Usługodawcy, konfigurowane i przesyłane w
            ramach Platformy;
          </li>
          <li>
            <strong>Peryferia</strong> - sprzęty elektroniczne, w szczególności,
            lecz nie wyłącznie:
            <ul>
              <li>drukarki fiskalne,</li>
              <li>terminale płatnicze,</li>
              <li>wagi,</li>
              <li>wagoskanery,</li>
              <li>skanery kodów QR,</li>
              <li>
                skanery kodów paskowych, będące elementami odrębnymi od Urządzeń
                i Zbioru Aplikacji Rapidshop. Peryferia mogą być połączone z
                Urządzeniami za pomocą sieci informatycznej, przewodu USB, portu
                szeregowego, bądź za pomocą bezprzewodowych technologii przesyłu
                informacji takich jak bluetooth, wifi.
              </li>
            </ul>
          </li>
          <li>
            <strong>Aplikacja Softpos</strong> - odrębne od Zbioru Aplikacji
            Rapidshop oprogramowanie pod postacią aplikacji mobilnej, którego
            główną funkcją jest wykonywanie transakcji płatniczych;
          </li>
          <li>
            <strong>Formularz Zamówienia</strong> - załącznik do niniejszej
            Umowy, określający listę dostępnych funkcji i aplikacji, do używania
            których będzie uprawniony Usługobiorca w ramach zawartej Umowy, wraz
            z należnościami dla Usługodawcy. Formularz Zamówienia może mieć
            formę elektroniczną - w postaci strony internetowej, lub w postaci
            wiadomości elektronicznej, albo formę pisemną.
          </li>
          <li>
            <strong>Partner Sprzętowy</strong> - spółka prawa handlowego
            posiadająca stosowną umowę z Usługodawcą w zakresie dystrybucji
            Urządzeń i Peryferiów.
          </li>
          <li>
            <strong>Dostawca Usług Płatniczych</strong> - spółka operująca i
            zarządzająca terminalami płatniczymi, bramkami płatniczymi online
            oraz Aplikacjami Softpos, posiadająca status agenta rozliczeniowego.
          </li>
        </ul>
        <h2>§ 1. Warunki korzystania</h2>
        <ul>
          <li>
            1.1. Właścicielem oraz podmiotem zarządzającym aplikacjami Zbioru
            Aplikacji Rapidshop jest Usługodawca.
          </li>
          <li>
            1.2. Jakiekolwiek prawa do aplikacji stanowiących Zbiór Aplikacji
            Rapidshop, w tym w szczególności – choć niewyłącznie – autorskie
            prawa majątkowe do aplikacji stanowiących Zbiór Aplikacji Rapidshop
            przynależą do Usługodawcy.
          </li>
          <li>
            1.3. Warunkiem korzystania z aplikacji stanowiących Zbiór Aplikacji
            Rapidshop jest zatwierdzenie Formularza Zamówienia przygotowanego na
            podstawie Oferty złożonej potencjalnemu Usługobiorcy przez
            Usługodawcę.
          </li>
          <li>
            1.4. Zawierdzenie Formularza Zamówienia stanowi o zawarciu umowy
            świadczenia usług między Usługodawcą a Usługobiorcą.
          </li>
          <li>
            1.5. Przez Zawierdzenie Formularza Zamówienia rozumie się wyrażenie
            przez Usługobiorcę woli akceptacji Formularza Zamówienia, drogą
            elektroniczną lub na piśmie.
          </li>
          <li>
            1.6. Zawierdzenie Formularza Zamówienia oznacza:
            <ul>
              <li>
                akceptację przez Usługobiorcę ninisjszego Regulaminu w całości;
              </li>
              <li>
                zobowiązanie do zapłaty wszelkich należności Usługobiorcy na
                rzecz Usługodawcy wyszczególnionych na Formularzu Zamówienia w
                opisanych na Formularzu Zamówienia terminach, na rachunek
                bankowy Usługodawcy podany na Formularzu Zamówienia.
              </li>
            </ul>
          </li>
          <li>
            1.7. Warunkiem korzystania z aplikacji stanowiących Zbiór Aplikacji
            Rapidshop jest regularne i terminowe dokonywanie opłat
            wyszczególnionych na Formularzu Zamówienia zgodnie z podanymi w
            tymże Formularzu terminami.
          </li>
          <li>
            1.8. Aplikacja POS jest przeznaczona do:
            <ul>
              <li>
                Wspomagania procesu dokonywania umów sprzedaży pomiędzy
                Usługobiorcą a Klientami;
              </li>
              <li>
                Organizacji procesu odbioru produktów zamówionych u Usługobiorcy
                przez Użytkowników Kanałów Online bądź osoby przez tych
                Użytkowników uprawnione.
              </li>
            </ul>
          </li>
          <li>
            1.9. Usługodawca nie jest stroną Umów sprzedaży zawieranych pomiędzy
            Usługobiorcą a Klientami.
          </li>
          <li>
            1.10. Do uruchamiania Aplikacji POS wymagane jest uprzednie
            przygotowanie Urządzenia przez Usługodawcę. Przez przygotowanie
            rozumie się w szczególności zainstalowanie Aplikacji Rapid SAFEPOS.
          </li>
          <li>
            1.11. Do uruchamiania Aplikacji POS i Aplikacji Rapid SAFEPOS,
            wymagany jest:
            <ul>
              <li>dostęp do sieci Internet;</li>
            </ul>
          </li>
          <li>
            1.12. Aby skorzystać z funkcjonalności Platformy oraz dostępu do
            oferowanych przez nią usług, Usługobiorca musi posiadać Urządzenie
            spełniające następujące wymagania:
            <ul>
              <li>dostęp do sieci Internet;</li>
              <li>zainstalowany aktualny System operacyjny;</li>
              <li>
                w przypadku systemów Microsoft Windows - zainstalowane
                oprogramowanie antywirusowe w aktualnej wersji oraz z aktualną
                bazą wirusów;
              </li>
              <li>
                zainstalowana przeglądarkę Google Chrome w najnowszej wersji;
              </li>
            </ul>
          </li>
          <li>
            1.13. Na Formularzu Zamówienia, Usługobiorca może zamówić u
            Usługodawcy Urządzenia oraz inny sprzęt.
            <ul>
              <li>
                1.13.1. Wraz z zatwierdzeniem lub podpisaniem Formularza,
                Usługobiorca zamawia sprzęt określony na Formularzu w celu
                późniejszego zakupu przez Usługobiorcę. Usługodawca zobowiązuje
                się do zorganizowania dostawy sprzętu w określonym na Formularzu
                Zamówienia terminie. Usługobiorca zobowiązuje się zapłacić
                określoną w Zamówieniu należność.
              </li>
              <li>
                1.13.2. Usługodawca w ramach zamówienia Urządzeń, o którym mowa
                w pkt. 1.12, nieodpłatnie zainstaluje na tych Urządzeniach
                aplikację Rapid SAFEPOS.
              </li>
              <li>
                1.13.3. Usługodawca zgodnie z Art. 558. Kodeksu Cywilnego
                wyłącza uprawnienia Usługobiorcy w zakresie rękojmi w stosunku
                do zamawianych Urządzeń.
              </li>
              <li>
                1.13.4. Niektóre z urządzeń, które zostały zamówione na
                podstawie Formularza Zamówienia, obowiązuje udzielana na
                podstawie odrębnej Umowy gwarancja Partnera Sprzętowego.
              </li>
              <li>
                1.13.5. W przypadku potrzeby skorzystania z gwarancji udzielanej
                przez Partnera Sprzętowego, Strony wspólnie uzgodnią sposób
                dostawy Urządzeń do Partnera Sprzętowego.
              </li>
            </ul>
          </li>
          <li>
            1.14. Usługobiorca nie uczestniczy w procesie autoryzacji płatności
            kartowej w ramach systemu Dostawcy Usług Płatniczych, a także w
            procesie przekazywania środków ze zleconych transakcji płatnicznych
            na rzecz Usługobiorcy.
          </li>
        </ul>
        <h2>§ 2. Prawa Usługobiorcy</h2>
        <ul>
          <li>
            2.1. Usługobiorca, który zawarł umowę z Usługodawcą zgodnie z pkt.
            1.4, w ramach czasowych określonych w Zamówieniu, pod warunkami
            określonymi w pkt 1.5., udziela niewyłącznego, ograniczonego w
            czasie i miejscu prawa do użytkowania aplikacji stanowiących Zbiór
            Aplikacji Rapidshop, na następujących polach eksploatacji: publiczne
            wykonanie, wystawienie, wyświetlenie, odtworzenie, reemitowanie.
          </li>
          <li>
            2.2. Usługodawca upoważnia Usługobiorcę do korzystania z Aplikacji
            POS w swoim Miejscu Sprzedaży określonym w Formularzu Zamówienia.
          </li>
          <li>
            2.3. Usługobiorca może uruchomić liczbę kopii (instancji) Aplikacji
            POS określoną w Formularzu Zamówienia.
          </li>
          <li>
            2.4. Usługobiorca w czasie obowiązywania Upoważnienia Usługodawcy
            posiada prawo do pobierania aktualizacji Aplikacji POS i Aplikacji
            Ekosystemu.
          </li>
          <li>
            2.5. Usługobiorca może korzystać z Aplikacji POS i Aplikacji
            Ekosystemu wyłącznie na Urządzeniach przygotowanych zgodnie z
            punktem 1.10 niniejszego Regulaminu.
          </li>
          <li>
            2.6. Usunięcie aplikacji Rapid SAFEPOS w czasie trwania prawa o
            którym mowa w pkt. 2.1 niniejszego Regulaminu, może skutkować
            rozwiązaniem Umowy o której mowa w pkt. 1.4 niniejszego Regulaminu.
          </li>
          <li>
            2.7. Jeżeli Usługobiorca chcę usunąć aplikację Rapid SAFEPOS z
            Urządzeń po zakończeniu obowiązywania Umowy o której mowa w pkt. 1.4
            niniejszego Regulaminu, jest to możliwe, a usługa usunięcia
            realizowana jest przez Usługodawcę w jego Siedzibie.
            <ul>
              <li>
                2.7.1 W przypadku gdy Usługobiorca jest zainteresowany
                usunięciem aplikacji, powinien on na własny koszt dostarczyć
                właściwe Urządzenia pod adres siedziby Usługodawcy.
              </li>
              <li>
                2.7.2 Za usługę usunięcia aplikacji Rapid SAFEPOS, Usługodawca
                pobiera opłatę w wysokości 300 zł od Urządzenia + koszt
                ewentualnej przesyłki.
              </li>
              <li>
                2.7.3 Usunięcie aplikacji Rapid SAFEPOS może skutkować całkowitą
                utratą danych zapisanych na Urządzeniu.
              </li>
            </ul>
          </li>
          <li>
            2.8. Usługodawca umożliwia Usługobiorcy instalację aplikacji Rapid
            SAFEPOS na urządzeniach już posiadanych przez Usługobiorcę, pod
            warunkiem spełnienia wymagań technicznych definiowanych przez
            Usługodawcę.
            <ul>
              <li>
                2.8.1. Usługa instalacji Rapid SAFEPOS na urządzeniach już
                posiadanych przez Usługobiorcę jest usługą odpłatną.
              </li>
              <li>
                2.8.2. Wysokość opłaty za instalację Rapid SAFEPOS na
                urządzeniach już posiadanych przez Usługobiorcę będzie ustalona
                przez Usługodawcę na Formularzu Zamówienia.
              </li>
            </ul>
          </li>
        </ul>
        <h2>§ 3. Obowiązki Usługobiorcy</h2>
        <ul>
          <li>
            3.1. Usługobiorca zobowiązany jest do przesyłania na Platformę tylko
            takich Multimediów, do których Usługobiorca posiada majątkowe prawa
            autorskie umożliwiające publikacje lub jakiekolwiek inne
            przetwarzanie w ramach aplikacji stanowiących Zbiór Aplikacji
            Rapidshop
          </li>
          <li>
            3.2. Usługobiorca nie ma prawa do podejmowania prób inżynierii
            wstecznej (tzw. reverse engineering) aplikacji stanowiących Zbiór
            Aplikacji Rapidshop.
          </li>
          <li>
            3.3. Usługobiorca, bez pisemnego upoważnienia Usługodawcy, nie może
            wprowadzać do aplikacji stanowiących Zbiór Aplikacji Rapidshop
            żadnych ulepszeń, modyfikacji bądź przeróbek.
          </li>
          <li>
            3.4. Usługobiorca, bez pisemnego upoważnienia Usługodawcy, nie może
            dokonywać inżynierii wstecznej ani wykorzystywać w sposób inny niż w
            ramach dostarczanych przez Usługodawcę aplikacji protokołów
            komunikacyjnych wykorzystywanych przez Aplikację POS i Aplikacje
            Ekosystemu.
          </li>
          <li>
            3.5. Niedozwolone są jakiekolwiek działania Usługobiorcy, które mogą
            w jakikolwiek sposób utrudniać lub uniemożliwiać działanie elementów
            Zbioru Aplikacji Rapidshop lub które mogą narazić na szkodę lub
            dyskomfort Usługodawcę lub pozostałych Usługobiorców korzystających
            z Platformy lub jakąkolwiek inną osobę lub podmiot, lub które są
            niezgodne z powszechnie obowiązującym prawem lub niniejszym
            Regulaminem lub porządkiem publicznym.
          </li>
          <li>
            3.6. Usługobiorca zobowiązany jest do powstrzymania się od:
            <ul>
              <li>
                dostarczania treści o charakterze bezprawnym za pośrednictwem
                Platformy i Urządzeń;
              </li>
              <li>
                korzystania z Platformy z pominięciem poszanowania praw osób
                trzecich, w tym ich dóbr osobistych oraz praw autorskich;
              </li>
              <li>
                udostępniania danych dostępowych Usługobiorcy osobom trzecim;
              </li>
              <li>
                podejmowania jakichkolwiek działań, które mogących utrudnić lub
                zakłócić funkcjonowanie Platformy oraz od korzystania z
                Platformy w sposób uciążliwy dla innych Usługobiorców,
              </li>
              <li>
                wykorzystywania Platformy w sposób sprzeczny z jej
                przeznaczeniem, z prawem, dobrymi obyczajami, naruszający dobra
                osobiste osób trzecich lub uzasadnione interesy Usługodawcy.
              </li>
            </ul>
          </li>
          <li>
            3.7. Usługobiorca ponosi pełną i wyłączną odpowiedzialność za
            wszelkie przypadki swojego bezprawnego działania na Platformie – tak
            w stosunku do Usługodawcy, innych Usługobiorców jak i w stosunku do
            Klientów, Kasjerów, Użytkowników Kanałów Online oraz do
            jakichkolwiek innych osób lub podmiotów.
          </li>
          <li>
            3.8. Usługobiorca jest zobowiązany do umieszczania opisów i zdjęć
            Produktów, które nie wprowadzają w błąd Klientów zarówno co do
            wyglądu Produktu jak i jego przeznaczenia.
          </li>
          <li>
            3.9. Usługobiorca w swoim Miejscu Sprzedaży zobowiązany jest do
            zapewnienia odpowiednich środków bezpieczeństwa, w szczególności,
            lecz nie wyłącznie:
            <ul>
              <li>
                do posiadania dedykowanej dla Urządzeń i Peryferiów, izolowanej
                od publicznego dostępu lokalnej sieci informatycznej (LAN). W
                przypadku sieci umożliwiającej bezprzewodową transmisję danych
                Wi-Fi - zabezpieczenie tej sieci hasłem w standardzie minimum
                WPA2;
              </li>
              <li>
                do posiadania w sieci LAN w punkcie stycznym z siecią publiczną
                Internet rozwiązania "firewall", chroniącego sieć prywatną przed
                ingerencją zewnętrzną z sieci publicznych;
              </li>
              <li>
                organizacji nadzoru nad Kioskami przez Kasjerów lub inne osoby
                wyznaczone przez Usługobiorcę;
              </li>
              <li>
                ograniczenia dostępu do Punktów Sprzedaży do grona Kasjerów i
                ustanowienia dla nich silnych Haseł Kasjerów;
              </li>
              <li>
                ustanowienie silnego Hasła Administratora dla każdego z
                Urządzeń;
              </li>
              <li>
                w razie kradzieży Urządzeń, Usługobiorca niezwłocznie powiadomi
                Usługodawcę o tym fakcie.
              </li>
            </ul>
          </li>
          <li>
            3.10. Usługobiorca zobowiązuje się do odbierania Korespondencji
            elektronicznej wysyłanej przez Usługodawcę związanej bezpośrednio z
            realizacją niniejszej Umowy. Korespondencja będzie wysyłana na adres
            poczty elektronicznej określony na Formularzu Zamówienia.
          </li>
          <li>
            3.11. Usługobiorca ma obowiązek podania na Platformie nazwy, adresu
            siedziby, oraz innych wymaganych danych identyfikujących swoją firmę
            i Miejsce Sprzedaży takich jak numer identyfikacji podatkowej, regon
            w celu:
            <ul>
              <li>
                umiejscowienia siedziby jego działalności w pamięci aplikacji ze
                Zbioru Aplikacji Rapidshop;
              </li>
              <li>
                przesłania poprawnych i właściwych danych Usługobiorcy do
                dostawców rozwiązań fiskalnych, dostawców usług płatniczych,
                urzędów i innych instytucji, do których z mocy prawa lub mocy
                innych umów Usługodawca jest zobowiązany wysłać takie dane.
              </li>
            </ul>
          </li>
          <li>
            3.12. Jeżeli wymaga tego lokalne prawo, Usługobiorca ma obowiązek
            podłączenia do Aplikacji POS i Kiosku - za pomocą połączenia
            bezprzewodowego lub przewodowego - drukarki fiskalnej, bądź
            zintegrowania Kasy rejestrującej mającej postać oprogramowania, bądź
            do zintegrowania prawnego odpowiednika kasy rejestrującej dla krajów
            innych niż Polska przed otwarciem pierwszego rachunku.
          </li>
          <li>
            3.13. Usługobiorca zgadza się na otrzymywanie faktur VAT drogą
            elektroniczną na wskazany przy Rejestracji adres email.
          </li>
          <li>
            3.14. Usługobiorca jest odpowiedzialny za działanie i zaniechanie
            osób, którym nadał uprawnienia do korzystania z aplikacji ze Zbioru
            Aplikacji Rapidshop w jego imieniu.
          </li>
        </ul>
        <h2>$ 4. Prawa Usługodawcy</h2>
        <ul>
          <li>
            4.1. Usługodawca zastrzega, że w każdym przypadku w trakcie
            korespondencji bądź wymiany informacji między Usługobiorcą i
            Usługodawcą mowa jest o "sprzedaży rozwiązania", "sprzedaży
            aplikacji", "sprzedaży Rapidshopa", rozumie się przez to odpłatne
            przekazanie niewyłącznego, ograniczonego czasowo prawa do
            użytkowania aplikacji ze Zbioru Aplikacji Rapidshop, zgodnie z
            punktem 2.1. niniejszego Regulaminu.
          </li>
          <li>
            4.2. Usługodawca ma prawo pozbawić Usługobiorcy praw o których mowa
            w art. 2.1. niniejszego Regulaminu na skutek:
            <ul>
              <li>
                4.2.1. Upływu terminów zdefiniowanych w Formularzu Zamówienia;
              </li>
              <li>
                4.2.2. Stwierdzenia braku otrzymania należności o których mowa w
                punkcie 1.6. niniejszego Regulaminu, przy czym przed
                pozbawieniem Usługobiorcy praw Usługodawca podejmie jedną próbę
                poinformowania Usługobiorcy o ryzyku pozbawienia praw;
              </li>
              <li>
                4.2.3. Stwierdzenia odinstalowania aplikacji Rapid SAFEPOS przez
                Usługobiorcę z jakiegokolwiek Urządzenia będącego przedmiotem
                Formularza Zamówienia przed wygaśnięciem terminów zawartych w
                Formularzu Zamówienia;
              </li>
              <li>
                4.2.4. Stwierdzenia, że Usługobiorca nie używa aplikacji będącej
                częścią Zbioru Aplikacji Rapidshop zgodnie z jej przeznaczeniem,
                w szczególności kiedy działalność Usługobiorcy wyrządza
                jakąkolwiek szkodę innemu Usługobiorcy uczestniczącego w
                Platformie;
              </li>
              <li>
                4.2.5. Stwierdzenia prób inżynierii wstecznej (reverse
                engeneering) aplikacji będącej częścią Zbioru Aplikacji
                Rapidshop bądź innych działań poważnie naruszających interesy
                Usługodawcy.
              </li>
            </ul>
          </li>
          <li>
            4.3. Jeżeli Usługobiorca traci prawa w następstwie zdarzeń opisanych
            w punkcie 4.2. niniejszego Regulaminu, Usługodawca ma prawo do
            zadośćuczynienia w wysokości kwot, które byłyby należne Usługodawcy
            gdyby Usługobiorca nie spowodował zdarzeń wyszczególnionych w
            rzeczonych punktach. Zadośćuczynienia te nie wyłączają prawa
            Usługodawcy do dochodzenia innych roszczeń.
          </li>
          <li>
            4.4. Usługodawca zastrzega sobie prawo do:
            <ul>
              <li>
                przejściowego zaprzestania funkcjonowania Platformy ze względu
                na przerwę konserwacyjną lub czynności związane z modyfikacją
                Platformy w wymiarze do 48 godzin miesięcznie;
              </li>
              <li>
                wysyłania na adres e-mail Usługobiorcy wykorzystanego w procesie
                Rejestracji komunikatów technicznych, prawnych i transakcyjnych
                związanych z funkcjonowaniem Platformy w szczególności:
                powiadomień związanych z działaniem Platformy; wiadomości
                umożliwiających zresetowanie hasła; komunikatów planowanej
                przerwie konserwacyjnej; wiadomości potwierdzającej adres email
                Usługobiorcy; powiadomienia o obsłudze zgłoszenia Usługobiorcy;
                powiadomienie o zablokowaniu dostępu do Platformy; powiadomienia
                o zmianach w Regulaminie; powiadomienia o zagrożeniach danych
                Usługobiorcy; dowolnego personalizowania treści informacji
                wysyłanych do Usługobiorcy; elektronicznej faktury VAT z tytułu
                korzystania przez Usługobiorcę z Aplikacji POS, Aplikacji
                Ekosystemu lub Platformy.
              </li>
            </ul>
          </li>
        </ul>
        <h2>§ 5. Wyłączenia odpowiedzialności</h2>
        <ul>
          <li>
            5.1. Aplikacja POS, jak i Aplikacja Ekosystemu są dostarczane
            Usługobiorcy "as is", czyli "jak jest" - Usługobiorca akceptuje
            funkcjonalność i zawartość oprogramowania będącego Aplikacją POS,
            Aplikacjami Ekosystemu i Platformą w takiej formie i zakresie
            funkcjonalnym, w jakim została dostarczona.
          </li>
          <li>
            5.2. Usługodawca nie ponosi odpowiedzialności za zakłócenia w
            prawidłowym funkcjonowaniu elementów Aplikacji Rapidshop wywołane
            siłą wyższą, Awarią sieci telekomunikacyjnych lub Awarią
            infrastruktury technicznej lub Awarią techniczną, atakiem hakerów,
            wystąpieniem Przerwy konserwacyjnej oraz w przypadku, gdy
            Usługobiorca nie spełni wymagań opisanych w punkcie 3.9. lub 3.12.
            niniejszego Regulaminu.
          </li>
          <li>
            5.3. Usługodawca nie ponosi odpowiedzialności za czasową niemożność
            korzystania z Platformy lub poszczególnych jej funkcjonalności
            spowodowaną Przerwą konserwacyjną lub wprowadzaniem ulepszeń lub
            zmian technologicznych. O zmianach, które z uwagi na okres trwania
            ich wdrożenia, mogą w sposób istotny wpłynąć na komfort korzystania
            z Platformy (Przerwy konserwacyjnej) Usługobiorcy, o ile będzie to
            możliwe, zostaną zawczasu powiadomieni w drodze stosownego
            komunikatu Usługodawcy.
          </li>
          <li>
            5.4. Usługodawca nie jest odpowiedzialny za szkody poniesione przez
            Usługobiorcę na skutek korzystania z elementów zbioru Aplikacji
            Rapidshop, jak również Usługodawca nie odpowiada za utratę przez
            Usługobiorcę możliwych dochodów na skutek braku dostępu do elementów
            zbioru Aplikacji Rapidshop. Usługobiorca powinien stosować
            odpowiednie aplikacje, oprogramowanie lub inne środki techniczne, w
            tym oprogramowanie chroniące prywatność, hasła oraz antywirusowe, w
            celu bezpiecznego korzystania ze Zbioru Aplikacji Rapidshop.
            Usługobiorca, korzystając z Aplikacji POS lub Platformy, powinien
            dokonywać samodzielnej weryfikacji pozyskiwanych treści oraz
            informacji.
          </li>
          <li>
            5.5. Usługodawca nie odpowiada za utracenie danych zgromadzonych na
            Platformie, które wydarzyło się w szczególności na skutek awarii
            technicznej lub innego działania niespowodowanego celowo przez
            Usługodawcę. Usługobiorca powinien we własnym zakresie sporządzać
            regularne kopie zapasowe danych.
          </li>
          <li>
            5.6. Usługodawca nie gwarantuje, że Aplikacja POS, Aplikacje
            Ekosystemu i Platforma będą działać zgodnie z założeniami
            Usługobiorcy - Usługodawca odpowiada jedynie za dostarczenie funkcji
            zdefiniowanych w Formularzu Zamówienia.
          </li>
          <li>
            5.7. Usługodawca zgodnie z Art. 558. Kodeksu Cywilnego wyłącza swoją
            odpowiedzialność majątkową za awarie techniczne które mogą pojawić
            się w toku użytkowania aplikacji. Usługodawca nie wypłaci żadnych
            zadośćuczynień ani odszkodowań za utracone korzyści ani poniesione
            straty związane z użytkowaniem aplikacji ze Zbioru Aplikacji
            Rapidshop.
          </li>
          <li>
            5.8. Usługodawca nie ponosi odpowiedzialności za jakiekolwiek błędy
            w Bazie Produktów, wynikające z błędów lub nieścisłości opisów
            Produktów lub ich zdjęć, błędów wynikających z przetwarzania danych
            lub z jakichkolwiek innych powodów.
          </li>
          <li>
            5.9. Jeżeli w drodze postępowania przed Sądem zostanie ustalona
            jakakolwiek odpowiedzialność Usługodawcy, Strony zgadzają się na to,
            że maksymalne odszkodowanie wypłacone Usługobiorcy przez Usługodawcę
            nie przekroczy równowartości trzech łącznych kwot miesięcznych za
            uzyskanie praw korzystania ze Zbioru Aplikacji Rapidshop.
          </li>
        </ul>
        <h2>§ 6. Reklamacje</h2>
        <ul>
          <li>
            6.1 Usługobiorca jest uprawniony w każdym czasie do zgłaszania
            reklamacji dotyczących nieprawidłowego funkcjonowania Aplikacji lub
            nieprawidłowego sposobu świadczenia usług przez Usługodawcę w drodze
            pisemnej, tj. listownie na adres siedziby Usługodawcy lub drogą
            email na adres kontakt@appidea.pl.
          </li>
          <li>
            6.2 Reklamacja powinna zawierać opis problemu oraz dane
            umożliwiające identyfikację osoby dokonującej zgłoszenia
            reklamacyjnego, tj. co najmniej imię i nazwisko oraz adres email
            zgłaszającego. Jeżeli dane zgłaszającego będą w tym zakresie
            wymagały uzupełnienia, Usługodawca zwróci się do zgłaszającego – o
            ile będzie to możliwe zważywszy na niekompletność danych – o ich
            uzupełnienie. Usługodawca może również zwrócić się do zgłaszającego
            z prośbą o uzupełnienie reklamacji o inne informacje niezbędne dla
            jej rozpatrzenia, np. dotyczące szczegółowego przebiegu zdarzenia,
            którego dotyczy reklamacja. W przypadku skierowania do Usługodawcy
            reklamacji, w której nie będzie możliwe zidentyfikowanie osoby, od
            której ona pochodzi, reklamację taka pozostawia się bez rozpoznania.
          </li>
          <li>
            6.3 Usługodawca rozpatruje reklamacje w terminie 14 dni od dnia
            otrzymania prawidłowo złożonej reklamacji, tj. obejmującej wszystkie
            dane i informacje niezbędne dla udzielenia odpowiedzi. W przypadku
            uzupełniania danych przez Usługobiorcę składającego reklamację,
            termin wskazany w zdaniu poprzedzającym biegnie na nowo, od momentu
            uzupełnienia wszystkich wymaganych informacji. Odpowiedź na
            reklamację udzielana jest w ten sam sposób, w jaki została ona
            zgłoszona do Usługodawcy.
          </li>
        </ul>
        <h2>§ 7. Upoważnienia dla Usługodawcy</h2>
        <ul>
          <li>
            7.1. W związku z możliwym przekazywaniem pomysłów lub wskazówek ze
            strony Usługobiorcy na rzecz Usługodawcy, w tym ustnie, na piśmie
            lub drogą elektroniczną, Usługobiorca przekazuje nieodpłatnie
            niewyłączne autorskie prawa majątkowe do tych pomysłów lub wskazówek
            na rzecz Usługodawcy, na wszelkich polach eksplotacji, w zakresie
            powielania, przetwarzania oraz publicznego ich wykorzystywania.
          </li>
          <li>
            7.2. W związku z wypełnianiem przez Usługodawcę postanowień
            niniejszego Regulaminu, Usługobiorca upoważnia Usługodawce i
            podmioty upoważnione przez Usługodawcę do przetwarzania danych
            osobowych Usługobiorcy, Kasjerów i innych osób związanych zawodowo z
            Usługobiorcą w zakresie wymaganym do realizacji usług przez
            Usługodawcę.
          </li>
          <li>
            7.3. Usługobiorca upoważnia Usługodawcę do przetwarzania danych
            mogących stanowić tajemnicę przedsiębiorstwa Usługobiorcy, w
            szczególności: cenników, stanów magazynowych, zdjęć produktów,
            danych pochodzących z Systemów informatycznych należących do
            Usługobiorcy, czasu pracy Kasjerów.
          </li>
        </ul>
        <h2>§ 8. Postanowienia końcowe</h2>
        <ul>
          <li>
            8.1 Prawem właściwym dla rozwiązywania sporów wynikających z
            Regulaminu, korzystania z Aplikacji jest prawo polskie.
          </li>
          <li>
            8.2 Jeżeli niniejszy regulamin został dostarczony wraz z
            tłumaczeniem na język inny niż polski, wersją niniejszego Regulaminu
            będącą wiążącą dla Stron jest wersja polska.
          </li>
          <li>
            8.3 Usługodawcy przysługuje prawo do wprowadzenia zmian do
            Regulaminu. Zmiany Regulaminu wchodzą w życie w terminie 7 dni od
            dnia jego opublikowania w Aplikacji. Publikacja odbywa się poprzez
            aktualizację treści, do których odsyła stosowny link dostępny na
            stronie rapidshop.pl. Usługobiorca akceptuje postanowienia
            Regulaminu w jego najnowszej wersji. Usługobiorcy przysługuje prawo
            do nie zaakceptowania nowej treści Regulaminu, obowiązany jest w
            takim przypadku zawiadomić o tym Usługodawcę; tego rodzaju
            zawiadomienie jednoznaczne jest z rezygnacją Usługobiorcy z
            korzystania z praw o których mowa w pkt. 2.1. niniejszego Regulaminu
            i zapłaty wszystkich wcześniej ustalonych należności dla
            Usługodawcy.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TOC;
